import ClayDatePicker from "@clayui/date-picker";
import ClayForm from "@clayui/form";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import { ErrorMessage, Field } from "formik";
import React from "react";

import TextError from "./TextError";
import "../../../../components/screens/console/emailResponse/EmailResponse.scss"

function Date(props: any) {
  const { id, name, placeholder, dateFormat, years, validate, size = "sm" } = props;

  return (
    <div>
      <Field name={name} validate={validate}>
        {({ form, field }: { form: any; field: any }) => {
          const { setFieldValue } = form;
          const { value } = field;
          return (
            <ClayForm.Group
              className={`email-Border form-group-${size} ${
                form.errors[name] && form.touched[name] !== undefined ? "has-error" : ""
              }`}
            >
              <ClayDatePicker
                id={id}
                name={name}
                dateFormat={dateFormat || "yyyy-MM-dd"}
                placeholder={placeholder}
                value={value}
                onValueChange={(val) => setFieldValue(name, val)}
                years={years || { start: 1980, end: 2050 }}
                spritemap={claySpriteImagePath()}
                {...field}
                {...props}
              />
              <ErrorMessage name={name} component={TextError} />
            </ClayForm.Group>
          );
        }}
      </Field>
    </div>
  );
}

export default Date;
