import ClayNav from "@clayui/nav";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { navBarOptions } from "@constants";
import { ISignedinUser } from "@interfaces";
import { getSignedInUser } from "@store/userDetails/slice";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

function NavBar(props: any) {
  const envVars = useSelector(getEnvVars);
  const userDetails: ISignedinUser = useSelector(getSignedInUser);
  const location = useLocation();
  const { pathname } = location;

  const navOptions = navBarOptions(userDetails ? userDetails.role : "");

  return (
    <nav className="navBar brdcm-container">
      <div className="navBarBorder">
        <ClayNav>
          {navOptions.map((nav, index) => {
            return (
              nav.isVisible &&
              (nav.name !== "SCRT User Administration" ? (
                <ClayNav.Item key={index}>
                  <Link
                    className={`nav-link nav-text ${
                      pathname === nav.to ||
                      ((pathname === "/sites" || pathname.includes("/sites/")) &&
                        nav.to === "/sites") ||
                      (nav.to === "/repository" &&
                        (pathname === "/repository" || pathname.includes("/repository/")))
                        ? "active"
                        : ""
                    }`}
                    to={nav.to}
                    rel="noopener noreferrer"
                    style={{padding : ".625rem .625rem"}}
                  >
                    {nav.name === "Help" ? (
                      <i className="fa fa-question-circle fa-lg"></i>
                    ) : (
                      nav.name
                    )}
                  </Link>
                </ClayNav.Item>
              ) : (
                <ClayNav.Item key={index}>
                  <a
                    className={`nav-link nav-text`}
                    href={envVars.USERS_ADMINISTRATION}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {nav.name}
                  </a>
                </ClayNav.Item>
              ))
            );
          })}
        </ClayNav>
      </div>
    </nav>
  );
}

export default NavBar;
