import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { PageTitle, Table, TableFilterCheckbox } from "@common-components";
import TableCheckBox from "@components/checkbox/TableCheckbox";
import "./scrtReportManagement.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClayButton from "@clayui/button";
import { IReportManagementChecked, IReportManagementDetails } from "utils/interfaces/scrtReportManagement";
import { deleteReports, getSCRTRepoList, getSuccessFullDelete, loadSCRTReportManagementDetails, triggerSendToERP } from "@store/console/scrtReportManagement/slice";
import { ClayCheckbox, ClayInput, ClayToggle } from "@clayui/form";
import { MultiDeleteModal } from "@components/screens/repository/MultiDeleteModal";

const SCRTReportManagement = ()=>{

    const dispatch = useDispatch()

    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const [erpFlag,setErpFlag] = useState<boolean>(false)
    const [downloadReportIdObj, setDownloadReportIdObj] = useState<any>({});
    const [allCheckBoxSelected, setAllCheckBoxSelected] = useState<boolean>(false);
    const [data, setData] = useState<IReportManagementChecked[]>([])
    const [searchedSupportSite,setSearchedSupportSite] = useState<string>("")
    const [isSearchGoingOn, setIsSearchGoingOn] = useState<boolean>(false);
    const [searchedReportId,setSearchedReportId] = useState<string>("")
    const [hardDelete,setHardDelete] = useState<boolean>(false)
    const [showTable,setShowTable] = useState<boolean>(false)
    const [deleteModalFlag,setDeleteModalFlag] = useState<boolean>(false)
    const [modalType , setModalType]= useState<string>("")
    //const [flag1,setFlag1] = useState<boolean>(false)

    const rowData:IReportManagementDetails[]=useSelector(getSCRTRepoList)
    const successFullDelete:any=useSelector(getSuccessFullDelete)
    const pageTitle="SCRT Report Management"

    const toggleChange=(val:boolean)=>{
      setHardDelete(val);
      setIsSelectAll(false);
      setAllCheckBoxSelected(false);
      setDownloadReportIdObj({});
      
      let obj={
        siteId: searchedSupportSite,
        reportId: searchedReportId,
        deleteFlag: val,
        billable:false
      }
      dispatch(loadSCRTReportManagementDetails(obj))
    }  
    useEffect(()=>{
      if(successFullDelete){
        let obj={
          siteId: searchedSupportSite,
          reportId: searchedReportId,
          deleteFlag: hardDelete,
          billable:false
        }
        dispatch(loadSCRTReportManagementDetails(obj))
      }
    },[successFullDelete])  // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(()=>{
      const delayDebounceFn = setTimeout(() => {
        if (isSearchGoingOn) {
          setIsSearchGoingOn(false);
          
          if(searchedReportId.length===0 && searchedSupportSite.length===0){
            setShowTable(false)
            setIsSelectAll(false);
            setAllCheckBoxSelected(false);
            setDownloadReportIdObj({});
            setHardDelete(false)
            setErpFlag(false)
          }else{
            setShowTable(true)
            setErpFlag(false)
            let obj={
              siteId: searchedSupportSite,
              reportId: searchedReportId,
              deleteFlag: hardDelete,
              billable: false
            }
            dispatch(loadSCRTReportManagementDetails(obj))
          }
        }
      }, 750);
      return () => {
          clearTimeout(delayDebounceFn);
      };
    },[searchedReportId,searchedSupportSite]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleReportSearch=(val:string)=>{
      setSearchedReportId(val)
    }

    const handleSupportSearch=(val:string)=>{
      setSearchedSupportSite(val)
    }

    useEffect(() => {
        let count = 0;
        const newData:IReportManagementChecked[] =
          (rowData && rowData.length &&
            rowData.map((row:IReportManagementDetails) => {
              !!downloadReportIdObj[row.reportId] && (count += 1);
              return {
                ...row,
                isChecked: !!downloadReportIdObj[row.reportId],
              };
            })) ||
          [];

          setData(newData);
          setIsSelectAll(newData.length > 0 && newData.length === count);
      }, [rowData]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCheckboxChange = (index: number, value: boolean) => {
        const checkedData: any = [...data];
        const newDownloadReportIdObj: any = { ...downloadReportIdObj };
        checkedData[index].isChecked = value;
        if (value) {
          newDownloadReportIdObj[checkedData[index].reportId] = checkedData[index].reportId;
        } else {
          delete newDownloadReportIdObj[checkedData[index].reportId];
        }
        const len = checkedData.filter((i: any) => {
          return i.isChecked;
        }).length;
        
        setIsSelectAll(len === checkedData.length);
        setAllCheckBoxSelected(Object.values(newDownloadReportIdObj).length > 1);
        setData(checkedData);
        setDownloadReportIdObj(newDownloadReportIdObj);
    };
    
    const handleAllCheckboxChange = (value: boolean) => {
      const checkedData:IReportManagementChecked[] = [...data];
      const newDownloadReportIdObj: any = { ...downloadReportIdObj };
      checkedData.map((i: IReportManagementChecked) => {
        i.isChecked = value;
        if (value) {
          newDownloadReportIdObj[i.reportId] = i.reportId;
        } else {
          delete newDownloadReportIdObj[i.reportId];
        }
        return i;
      });
      setAllCheckBoxSelected(value);
      setIsSelectAll(value);
      setData(checkedData);
      setDownloadReportIdObj(newDownloadReportIdObj);
    };

    const handleDelete=(list:any)=>{
      document.body.style.overflow = "visible";
      
      setDeleteModalFlag(false)
      let obj={
        reportIds: list,
        hardDelete: modalType === "Hard Delete" ? true : false,
        sendtoErp:false
      }
      dispatch(deleteReports(obj))
      setModalType("")
      setIsSelectAll(false);
      setAllCheckBoxSelected(false);
      setDownloadReportIdObj({});
    }
    const toggleErp=(val:boolean)=>{
      setErpFlag(val)
      setHardDelete(false)
      setIsSelectAll(false);
      setAllCheckBoxSelected(false);
      setDownloadReportIdObj({});
      let obj={
        siteId: searchedSupportSite,
        reportId: searchedReportId,
        deleteFlag: false,
        billable:val
      }
      dispatch(loadSCRTReportManagementDetails(obj))
    }
    const sendToERP=(msnList:any)=>{
      let obj={
        reportIds: msnList,
        hardDelete: false,
        sendtoErp:true
      }
      dispatch(triggerSendToERP(obj));
    }
    const REPORT_COLUMNS = [
        {
          headerName: "",
          field: "",
          sortable: false,
          cellRenderer: <TableCheckBox handleCheckboxChange={handleCheckboxChange}></TableCheckBox>,
          filter: { show: data.length > 0, type: "selectCheckbox" },
          filterRenderer: (
            <TableFilterCheckbox
              handleAllCheckboxChange={handleAllCheckboxChange}
              isSelectAll={isSelectAll}
            />
          ),
          width:"6rem"
        },
        {
          headerName: "Report Id",
          field: "reportId",
          sortable: true,
          width:"6rem"
        },{
            headerName: "Upload Date",
            field: "uploadDate",
            sortable: true,
            width:"6rem"
          },{
            headerName: "Uploaded By",
            field: "uploadedBy",
            sortable: true,
            width:"6rem"
          },{
            headerName: "SCRT Customer Number",
            field: "customerNumber",
            sortable: true,
            width:"7rem"
          },{
            headerName: "Support Site Id",
            field: "supportSiteId",
            sortable: true,
            width:"6rem"
          },{
            headerName: "ERP Cust Account Name",
            field: "customerName",
            sortable: true,
            width:"6rem"
          },{
            headerName: "Report Period Start Date",
            field: "reportingPeriodStartDateEpoch",
            sortable: true,
            width:"8rem"
          },{
            headerName: "Machine Serial Number",
            field: "machineSerialNumber",
            sortable: true,
            width:"7rem"
          },{
            headerName: "Machine Type",
            field: "machineType",
            sortable: true,
            width:"6rem"
          },{
            headerName: "Machine Model",
            field: "machineModel",
            sortable: true,
            width:"6rem"
          },{
            headerName: "File Name",
            field: "fileName",
            sortable: true,
            width:"6rem"
          },{
            headerName: "Deleted",
            field: "deleted",
            sortable: true,
            width:"6rem"
          },{
            headerName: "Report Type",
            field: "reportType",
            sortable: true,
            width:"6rem"
          },{
            headerName: "Source",
            field: "source",
            sortable: true,
            width:"6rem"
          },
        
    ];
  
    return (
        <div>
            <ClayLayout.ContainerFluid view size={false}>
                <ClayLayout.Row justify="start" className="align-items-center">
                  <ClayLayout.Col className="d-flex align-items-center" size={6}>
                      <PageTitle title={pageTitle} />
                  </ClayLayout.Col>
                  <ClayLayout.Col className="d-flex align-items-center flex-row-reverse pt-4" size={6}>
                    { ((searchedReportId.length>0 || searchedSupportSite.length>0) && !erpFlag) &&
                    <>
                    <ClayButton 
                        className="ml-3"
                        displayType="secondary" 
                        type="button" 
                        disabled={Object.values(downloadReportIdObj).length===0}
                        onClick={()=>{
                          setModalType("Hard Delete")
                          setDeleteModalFlag(true)
                        }}
                    >
                        Hard Delete
                    </ClayButton>
                    {
                      !hardDelete &&
                      <ClayButton 
                        className="ml-3"
                        displayType="secondary" 
                        type="button" 
                        onClick={()=>{
                          setModalType("Soft Delete")
                          setDeleteModalFlag(true)
                        }}
                        disabled={Object.values(downloadReportIdObj).length===0}
                      >
                          Soft Delete
                      </ClayButton>
                    }
                    
                    </>
                    }
                    {
                      ((searchedReportId.length>0 || searchedSupportSite.length>0) && erpFlag) && 
                      <ClayButton 
                        className="ml-3"
                        displayType="primary" 
                        type="button" 
                        disabled={Object.values(downloadReportIdObj).length===0}
                        onClick={()=>{
                          sendToERP(Object.values(downloadReportIdObj))
                        }}
                    >
                        Send to ERP
                    </ClayButton>
                    }
                    
                  </ClayLayout.Col>
                  <ClayCard className="m-3 w-100">
                      <ClayCard.Body className="p-4">
                          <ClayLayout.Row className="mb-6">
                            <ClayLayout.Col size={3}>
                              <div className="serial-container">
                                <ClayInput
                                  type="text"
                                  id={"supportSiteId"}
                                  placeholder={"Support site Id"}
                                  value={searchedSupportSite}
                                  onChange={(event) => {
                                    setIsSearchGoingOn(true);
                                    handleSupportSearch(event.target.value)
                                }}
                                />
                              </div>
                            </ClayLayout.Col>
                            <ClayLayout.Col size={3}>
                              <div className="serial-container">
                                <ClayInput
                                  type="text"
                                  id={"reportId"}
                                  placeholder={"Report Id"}
                                  value={searchedReportId}
                                  onChange={(event) => {
                                    setIsSearchGoingOn(true);
                                    handleReportSearch(event.target.value)
                                }}
                                />
                              </div>
                            </ClayLayout.Col>
                            
                              <ClayLayout.Col size={3} className="d-flex align-items-center flex-row-reverse">
                              {((searchedReportId.length>0 || searchedSupportSite.length>0) && !erpFlag) && 
                              <div className="mt-1 d-flex justify-content-end">
                                <ClayToggle
                                  label={hardDelete ? "Hard Delete Only" : "Hard & Soft Delete"}
                                  toggled={hardDelete}
                                  onToggle={(toggleValue) => {
                                    toggleChange(toggleValue)
                                  }}
                                  disabled={(searchedReportId.length===0 && searchedSupportSite.length===0)}
                                />
                              </div>}
                            </ClayLayout.Col>
                            <ClayLayout.Col size={3} className="d-flex justify-content-end align-items-center">
                            {(searchedReportId.length>0 || searchedSupportSite.length>0) && 
                              <div className="d-inline-flex align-items-center"> 
                                <ClayCheckbox
                                  className=""
                                  disabled={(searchedReportId.length===0 && searchedSupportSite.length===0)}
                                  checked={erpFlag}
                                  onChange={(e) => { 
                                    toggleErp(e.target.checked) 
                                  }} > 
                                  <label className="pl-2 pt-1 mb-1"> 
                                    {'enable send to ERP'}
                                  </label>
                                </ClayCheckbox>
                              </div>
                            }
                            
                            </ClayLayout.Col>
                          </ClayLayout.Row>
                          {showTable &&
                          <Table
                              rowClassName="report-management-logs"
                              headClassName= {"report-management-table-filter"}
                              definitions={REPORT_COLUMNS}
                              handleCheckboxChange={handleCheckboxChange}
                              dataSource={data}
                              borderedColumns={false}
                              borderless={true}
                              headVerticalAlignment={"middle"}
                              headingNoWrap={true}
                              hover={false}
                              noWrap={true}
                              responsive={true}
                              responsiveSize={"sm"}
                              striped={true}
                              tableVerticalAlignment={"middle"}
                              showDeltasDropDown={true}
                              checkDisabledRow={false}
                              showPagination={true}
                          />
                          }
                      </ClayCard.Body>
                  </ClayCard>
                </ClayLayout.Row>
            </ClayLayout.ContainerFluid>
            {
              deleteModalFlag && 
               (
                  <MultiDeleteModal 
                    confirmDelete={handleDelete}
                    reportIdList={Object.values(downloadReportIdObj)}
                    modalClose={() => {
                      document.body.style.overflow = "visible";
                      setModalType("")
                      setDeleteModalFlag(false)
                    }}
                    hardDelete = { modalType === "Hard Delete"}
                    softDelete = { modalType === "Soft Delete"}
                    reportManagement={true}
                  />
                )
            }
        </div>
    )
}

export default SCRTReportManagement;