import { configureStore } from "@common-services/redux-helper/store/configureStore";

import { additionalMainframeReportingReducer } from "./additionalMainframeReporting/slice";
import { downloadDetailsReducer } from "./download/slice";
import { manageReducer } from "./manage/slice";
import { oktaDetailsReducer } from "./oktaAuth.slice";
import { overviewReducer } from "./overview/slice";
import { repositoryDetailsReducer } from "./repositoryDetails/slice";
import { repositoryLogsReducer } from "./repositoryLogs/slice";
import { siteDetailsReducer } from "./siteDetails/slice";
import { userDetailsReducer } from "./userDetails/slice";
import { scrtUserManagementReducer } from "./console/scrtUserManagement/slice"
import {scrtReportManagementReducer} from "./console/scrtReportManagement/slice"
import { emailResponseReducer } from "./console/emailResponse/slice";
import { catalogVersionReducer } from "./console/productCatalogManagement/slice";

export const store = configureStore({
  reducer: {
    userDetails: userDetailsReducer,
    repositoryDetails: repositoryDetailsReducer,
    siteDetails: siteDetailsReducer,
    oktaDetails: oktaDetailsReducer,
    manage: manageReducer,
    repositoryLogs: repositoryLogsReducer,
    overview: overviewReducer,
    additionalMainframeReporting: additionalMainframeReportingReducer,
    downloadDetails: downloadDetailsReducer,
    scrtUserManagement: scrtUserManagementReducer,
    scrtReportManagement:scrtReportManagementReducer,
    emailResponse:emailResponseReducer,
    catalogVersion:catalogVersionReducer
  },
});
