import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import { DEFAULT_DELTA } from "@constants";
import { createSlice } from "@reduxjs/toolkit";

import { REPOSITORY_LOGS_URL } from "./constants";


const defaultState = {
  userEventData: {},
  userEvent: [],
  userEventFilterVal: {},
  userEventPagination: {
    activeDelta: DEFAULT_DELTA,
    activePage: 0,
    ellipsisBuffer: 5,
    totalItems: 100,
  },
  userEventSort: { column: "", order: "" },

  reminderLogsData: {},
  reminderLogs: [],
  reminderLogsFilterVal: {},
  reminderLogsPagination: {
    activeDelta: DEFAULT_DELTA,
    activePage: 0,
    ellipsisBuffer: 5,
    totalItems: 100,
  },
  reminderLogsSort: { column: "", order: "" },

  repositoryLogsData: [],
  repositoryLogs: {},
  repositoryLogsFilterVal: {},
  repositoryLogsPagination: {
    activeDelta: DEFAULT_DELTA,
    activePage: 0,
    ellipsisBuffer: 5,
    totalItems: 100,
  },
  repositoryLogsSort: { column: "", order: "" },
  enableNotification: false,
  isConsoleReminder: false,
  isReminderFlag: null
};

const slice = createSlice({
  name: "repositoryLogs",
  initialState: { ...defaultState },
  reducers: {
    handleEventSortState: (state, action) => {
      if (action.payload) {
        state.userEventSort = action.payload;
      }
    },
    handleEventFilterState: (state, action) => {
      if (action.payload) {
        state.userEventSort = { column: "", order: "" };
        state.userEventFilterVal = action.payload;
      }
    },
    handleEventPaginationState: (state, action) => {
      if (action.payload) {
        state.userEventPagination = action.payload;
      }
    },
    userEventData: (state, action) => {
      if (action.payload && action.payload.success) {
        state.userEventData = action.payload?.data?.result || {};
      }
    },
    userEvent: (state, action) => {
      if (action.payload) {
        state.userEvent = action.payload || [];
      }
    },
    resetUserEventData: (state) => {
      state.userEventData = {};
      state.userEvent = [];
    },

    handleReminderLogsSortState: (state, action) => {
      if (action.payload) {
        state.reminderLogsSort = action.payload;
      }
    },
    handleReminderLogsFilterState: (state, action) => {
      if (action.payload) {
        state.reminderLogsSort = { column: "", order: "" };
        state.reminderLogsFilterVal = action.payload;
      }
    },
    handleReminderLogsPaginationState: (state, action) => {
      if (action.payload) {
        state.reminderLogsPagination = action.payload;
      }
    },
    reminderLogsData: (state, action) => {
      if (action.payload && action.payload.success) {
        if(action.payload.data && action.payload.data.isReminder){
          state.isReminderFlag = action.payload.data.isReminder ? action.payload.data.isReminder : null;
          state.enableNotification = action.payload.data.isReminder === "Y" ? true : false
        }else{
          state.enableNotification = false
        }
        //state.enableNotification=action.payload?.data?.isReminder || false
        state.reminderLogsData = action.payload?.data?.scrtEmailAuditList || {}; //action.payload?.data?.scrtEmailAuditList
      }
    },
    reminderLogs: (state, action) => {
      const payload = action.payload && action.payload.length ? action.payload : [];
      if (payload) {
        let logData = [...payload];
        logData.forEach((item, index) => {
          if (item.reminderInDays === 0) {
            item.reminderInDays = "N/A";
          }
          if (item.isReportReminder === "N") {
            item.isReportReminder = "MCL True Up";
          } else {
            item.isReportReminder = "MSN Missing";
          }
        });
        state.reminderLogs = logData || [];
      }
    },
    resetReminderLogsData: (state) => {
      state.reminderLogsData = {};
      state.reminderLogs = [];
    },

    handleLogsSortState: (state, action) => {
      if (action.payload) {
        state.repositoryLogsSort = action.payload;
      }
    },
    handleLogsFilterState: (state, action) => {
      if (action.payload) {
        state.repositoryLogsSort = { column: "", order: "" };
        state.repositoryLogsFilterVal = action.payload;
      }
    },
    handleLogsPaginationState: (state, action) => {
      if (action.payload) {
        state.repositoryLogsPagination = action.payload;
      }
    },
    repositoryLogsData: (state, action) => {
      if (action.payload && action.payload.success) {
        state.repositoryLogsData = action.payload?.data?.result || [];
      }
    },
    repositoryLogs: (state, action) => {
      if (action.payload) {
        state.repositoryLogs = action.payload || {};
      }
    },
    resetLogsData: (state) => {
      state.repositoryLogsData = [];
      state.repositoryLogs = {};
    },

    resetUserEventState: (state) => {
      state.userEventData = [];
      state.userEvent = [];
      state.userEventPagination = [];
      state.userEventFilterVal = {};
      state.userEventSort = [];
    },

    handleNotification: (state,action) => {
      let flag=state.enableNotification
      if(action.payload.success){
        state.enableNotification = !flag;
      }
    },

    handleIsConsoleReminder: (state,action) => {
      state.isConsoleReminder = action.payload;
    },

    handleIsReminderFlag:(state,action)=>{
      state.isReminderFlag = action.payload;
    },

    resetRepositoryLogsState() {
      return { ...defaultState };
    },
  },
});

export const {
  handleEventPaginationState,
  handleEventFilterState,
  handleEventSortState,
  userEvent,
  userEventData,
  resetUserEventData,
  resetUserEventState,
  resetRepositoryLogsState,

  handleReminderLogsSortState,
  handleReminderLogsFilterState,
  handleReminderLogsPaginationState,
  reminderLogs,
  reminderLogsData,
  resetReminderLogsData,

  handleLogsFilterState,
  handleLogsPaginationState,
  handleLogsSortState,
  repositoryLogs,
  repositoryLogsData,
  resetLogsData,
  handleNotification,
  handleIsConsoleReminder,
  handleIsReminderFlag
} = slice.actions;


export const changeReminderSetting = (data) => (dispatch, getState) => {
  
  return dispatch(
    apiCallBegan({
      url: REPOSITORY_LOGS_URL.UPDATE__REMINDER,
      method: "POST",
      data: data,
      onSuccess: handleNotification.type,
      onError: handleNotification.type,
    }),
  );
};

export const loadUserEventData = (sortParams, filterParams, pageParams) => (dispatch, getState) => {
  if (sortParams) {
    dispatch(handleEventSortState(sortParams));
  }
  if (filterParams) {
    dispatch(handleEventFilterState(filterParams));
  }
  if (pageParams) {
    dispatch(handleEventPaginationState(pageParams));
  }
  const { URL, body } = getUserEventURLWithParams(getState());
  return dispatch(
    apiCallBegan({
      url: URL,
      method: "POST",
      data: body,
      onSuccess: userEventData.type,
      onError: resetUserEventData.type,
      skipErrorHandling: true,
    }),
  );
};

export const loadRepositoryLogsData =
  (sortParams, filterParams, pageParams, isInternal, isAuditRepoLogs) => (dispatch, getState) => {
    if (sortParams) {
      dispatch(handleLogsSortState(sortParams));
    }
    if (filterParams) {
      dispatch(handleLogsFilterState(filterParams));
    }
    if (pageParams) {
      dispatch(handleLogsPaginationState(pageParams));
    }

    let { URL, body } = getRepositoryLogsURLWithParams(getState());
    if (isInternal) {
      body = { ...body, scrtInternal: true };
    } else if (isAuditRepoLogs) {
      body = { ...body, auditRepositoryPage: true };
    }

    return dispatch(
      apiCallBegan({
        url: URL,
        method: "POST",
        data: body,
        onSuccess: repositoryLogsData.type,
        onError: resetLogsData.type,
        skipErrorHandling: true,
      }),
    );
  };

//To fetch URL, request params and body
//@Input state
//@Output { URL, body }
const getRepositoryLogsURLWithParams = (state) => {
  let URL = REPOSITORY_LOGS_URL.GET_REPOSITORY_LOGS;
  let body = {
    date: "",
    filename: "",
    status: "",
    errorMessage: "",
  };

  if (state?.repositoryLogs?.repositoryLogsPagination) {
    URL += `?size=${state.repositoryLogs.repositoryLogsPagination.activeDelta}&page=${
      state.repositoryLogs.repositoryLogsPagination.activePage - 1
    }`;
  }
  if (state?.repositoryLogs?.repositoryLogsFilterVal) {
    const filters = { ...state.repositoryLogs.repositoryLogsFilterVal };
    body = { ...body, ...filters };
  }
  if (state?.repositoryLogs?.repositoryLogsSort?.column) {
    const sortOrder = state?.repositoryLogs?.repositoryLogsSort?.order
      ? state?.repositoryLogs?.repositoryLogsSort?.order.toUpperCase()
      : "ASC";
    URL += "&sort=" + state?.repositoryLogs?.repositoryLogsSort?.column + "," + sortOrder;
  }
  return { URL, body };
};
export const resetLogsFilters = () => (dispatch, getState) => {
  dispatch(handleLogsFilterState({}));
};

const getUserEventURLWithParams = (state) => {
  let URL = REPOSITORY_LOGS_URL.GET_USER_EVENT;
  let body = {
    userId: "",
    event: "",
    name: "",
    eventTimestamp: "",
  };
  if (state?.repositoryLogs?.userEventPagination) {
    URL += `?size=${state.repositoryLogs.userEventPagination.activeDelta}&page=${
      state.repositoryLogs.userEventPagination.activePage - 1
    }`;
  }
  if (state?.repositoryLogs?.userEventFilterVal) {
    const filters = { ...state.repositoryLogs.userEventFilterVal };
    body = { ...body, ...filters };
  }
  if (state?.repositoryLogs?.userEventSort?.column) {
    const sortOrder = state?.repositoryLogs?.userEventSort?.order
      ? state?.repositoryLogs?.userEventSort?.order.toUpperCase()
      : "ASC";
    URL += "&sort=" + state?.repositoryLogs?.userEventSort?.column + "," + sortOrder;
  }
  return { URL, body };
};

export const getReminderLogsDataApi =
  (sortParams, filterParams, pageParams) => (dispatch, getState) => {
    if (sortParams) {
      dispatch(handleReminderLogsSortState(sortParams));
    }
    if (filterParams) {
      dispatch(handleReminderLogsFilterState(filterParams));
    }
    if (pageParams) {
      dispatch(handleReminderLogsPaginationState(pageParams));
    }
    const { URL, body } = getReminderLogsURLWithParams(getState());

    return dispatch(
      apiCallBegan({
        url: URL,
        method: "POST",
        data: body,
        onSuccess: reminderLogsData.type,
        onError: resetReminderLogsData.type,
        skipErrorHandling: true,
      }),
    );
  };

const getReminderLogsURLWithParams = (state) => {
  let URL = REPOSITORY_LOGS_URL.GET_REMINDER_LOGS;
  let body = {
    isConsoleReminder: state?.repositoryLogs?.isConsoleReminder,
    supportSiteId: "",
    lastRemainderDate: "",
    serialNumber: "",
    reportingPeriodStartDate: "",
    reportingPeriodEndDate: "",
    trueupdPeriodStart: "",
    trueupdPeriodEnd: "",
    customernumber: "",
  };
  if (state?.repositoryLogs?.reminderLogsPagination) {
    URL += `?size=${state.repositoryLogs.reminderLogsPagination.activeDelta}&page=${
      state.repositoryLogs.reminderLogsPagination.activePage - 1
    }`;
  }
  if (state?.repositoryLogs?.reminderLogsFilterVal) {
    const filters = { ...state.repositoryLogs.reminderLogsFilterVal };
    body = { ...body, ...filters };
  }
  if (state?.repositoryLogs?.reminderLogsSort?.column) {
    const sortOrder = state?.repositoryLogs?.reminderLogsSort?.order
      ? state?.repositoryLogs?.reminderLogsSort?.order.toUpperCase()
      : "ASC";
    URL += "&sort=" + state?.repositoryLogs?.reminderLogsSort?.column + "," + sortOrder;
  }
  return { URL, body };
};

// selectors
export const resetUserEventFilters = () => (dispatch, getState) => {
  dispatch(handleEventFilterState({}));
};
export const resetReminderLogsFilters = (val,isConsoleReminderLogs) => (dispatch, getState) => {
  isConsoleReminderLogs ? dispatch(handleReminderLogsFilterState(val)) : dispatch(handleReminderLogsFilterState({}));
};
export const resetUserEventDataAndFilters = () => (dispatch, getState) => {
  dispatch(resetUserEventState({}));
};

export const getUserEvent = (state) => state.repositoryLogs.userEvent;
export const getUserEventData = (state) => state.repositoryLogs.userEventData;
export const getUserEventSortState = (state) => state.repositoryLogs.userEventSort;
export const getUserEventFilterState = (state) => state.repositoryLogs.userEventFilterVal;
export const getUserEventPageState = (state) => state.repositoryLogs.userEventPagination;

export const getReminderLogs = (state) => state.repositoryLogs.reminderLogs;
export const getReminderLogsData = (state) => state.repositoryLogs.reminderLogsData;
export const getReminderLogsSortState = (state) => state.repositoryLogs.reminderLogsSort;
export const getReminderLogsFilterState = (state) => state.repositoryLogs.reminderLogsFilterVal;
export const getReminderLogsPageState = (state) => state.repositoryLogs.reminderLogsPagination;

export const getRepoLogsSortState = (state) => state.repositoryLogs.repositoryLogsSort;

export const getRepoLogsFilterState = (state) => state.repositoryLogs.repositoryLogsFilterVal;

export const getRepoLogsPageState = (state) => state.repositoryLogs.repositoryLogsPagination;

export const getRepositoryLogs = (state) => state.repositoryLogs.repositoryLogs;

export const getRepositoryLogsData = (state) => state.repositoryLogs.repositoryLogsData;

export const getNotification = (state) => state.repositoryLogs.enableNotification;

export const getIsConsoleReminder = (state) => state.repositoryLogs.isConsoleReminder;  

export const getIsReminderFlag = (state) => state.repositoryLogs.isReminderFlag;

// reducer
export const repositoryLogsReducer = slice.reducer;

//@Input
//  name: "ABC,BCD", email: "abc@abc.com,bcd@bcd.com"
//@Output
//  "ABC (abc@abc.com), BCD (bcd@bcd.com)"
export const parseLogData = (data, key1, key2, dataKey = "erpAccountName", splitValue = ",") => {
  return [...data].map((row) => {
    const erpNames = (row[key1] && row[key1]?.split(splitValue)) || [];
    const erpNumbers = (row[key2] && row[key2]?.split(splitValue)) || [];
    const erpAccount =
      (erpNames.length &&
        erpNames
          .map((name, i) => {
            return `${name} (${erpNumbers[i]})`;
          })
          .join(", ")) ||
      "";
    return {
      ...row,
      [dataKey]: erpAccount,
    };
  });
};
