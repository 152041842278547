import { BASEPATH } from "../../utils/constants/constants";

export const REPOSITORY_DETAILS_URL = {
  GET_REPOSITORY_DETAILS: `${BASEPATH}/reports/search`,
  DELETE_REPORT: `${BASEPATH}/reports/delete`,
  BULK_DELETE: `${BASEPATH}/reports/delete-reports`,
  GET_PREVIEW_DETAILS: `${BASEPATH}/reports/preview`,
  UPLOAD_SCRT_REPORT: `${BASEPATH}/scrt/reports/uploadScrtReport`,
};

//To Convert Array of Objects into objects.
//@Input: n5_lpar_lmus: [{"lparname" : "OSE1" , "lparmsus" : "1344"},{},{}...]
//@Output: n5_lpar_lmus: {"OSE1":"1344" ,"":"", ....}
export const formatPreviewDetails = (data: any) => {
  if (!data) {
    return data;
  }
  let formatData: any = {};
  Object.keys(data).forEach((key) => {
    if (!Array.isArray(data[key])) {
      if (key === "n5_lpar_highest" && data[key] != null) {
        formatData[key] = { [data[key]["lparName"]]: data[key]["highest"] };
      } else {
        formatData[key] = { ...data[key] };
      }
    }
    // Check for n5_lpar_highest=null; add another else if to display data with 0 rows
    else {
      const items = [...data[key]];
      const parsedData: any = {};
      items.forEach((item) => {
        parsedData[item["lparName"]] = item["totalMsuConsumed"];
      });
      formatData[key] = { ...parsedData };
    }
  });

  return formatData;
};

export const REPOSITORY = "Repository";
export const AUDIT_REPOSITORY = "AuditRepository";
