import ClayButton from "@clayui/button";
import ClayIcon from "@clayui/icon";
import ClayModal, { useModal } from "@clayui/modal";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import React from "react";

export function UserDeleteModal(props: any) {
  const { modalClose, userDetail, confirmDelete } = props;
  const { observer } = useModal({
    onClose: () => modalClose(userDetail),
  });

  return (
    <ClayModal
      observer={observer}
      spritemap={claySpriteImagePath()}
      disableAutoClose={true}
      status="danger"
    >
      <ClayModal.Header>Confirmation</ClayModal.Header>
      <ClayModal.Body>
        {`Are you sure about deleting this user?`}
      </ClayModal.Body>
      <ClayModal.Footer
        last={
          <ClayButton.Group spaced>
            <ClayButton onClick={() => modalClose(userDetail)} borderless>
              <ClayIcon symbol="times" spritemap={claySpriteImagePath()} />
              &nbsp;{"No"}
            </ClayButton>
            <ClayButton onClick={() => confirmDelete(userDetail)} borderless>
              <ClayIcon symbol="check" spritemap={claySpriteImagePath()} />
              &nbsp;{"Yes"}
            </ClayButton>
          </ClayButton.Group>
        }
      />
    </ClayModal>
  );
}
