import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { PageTitle, Table, TableFilterCheckbox } from "@common-components";
import { useEffect, useState } from "react";
import { ClayInput,ClayToggle } from "@clayui/form";
import SiteModalTableRenderer from "@components/screens/sites/site/SiteModalTableRenderer";
import { FeedBackHeaderRenderer, getCommDecommDate, getUTCTime } from "@components/screens/sites/site/constants";
import {FEEDBACK_OPTIONS} from "../../sites/site/constants"
import { useDispatch, useSelector } from "react-redux";
import { getIsBillable, getSiteDetail, getUpdateDetailFlag, loadSiteDetailBySiteId, updateAdminSiteDetails, resetSiteDetail, triggerSendToERP } from "@store/siteDetails/slice";
import ClayButton from "@clayui/button";
import ClayIcon from "@clayui/icon";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import TableCheckBox from "@components/checkbox/TableCheckbox";
import { GET_USER_ROLE } from "@utils";
import MSNDateRenderer from "./MSNDateRenderer";
import { ISiteDetails, IUpdatedList } from "utils/interfaces/msnManagement";

let originalItemsObj: any = {};
let originalSerialNumberData: any = {};
let isSaveDisabled = true;

const MSNManagemet=()=>{
    const dispatch=useDispatch();
    const role = GET_USER_ROLE();
    const detailsObj=useSelector(getSiteDetail)
    const updateDetailFlag=useSelector(getUpdateDetailFlag)
    const isBillable=useSelector(getIsBillable)
    const [isSaveEnable,setIsSaveEnable] = useState<boolean>(false)
    const [updatedList,setUpdatedList] = useState<IUpdatedList[]>([])
    const [siteDetail,setSiteDetails]= useState<ISiteDetails[]>([])
    const [formData,setFormData] = useState({
        serialNumberActive: [],
        serialNumberDecommissioned: [],
        serialNumberFailOver: [],
        serialNumberDetails: [],
        trueUpPeriodStartDate: "",
        trueUpPeriodEndDate: "",
    })
    const [searchValue,setSearchValue] = useState<string>("")
    const [isSearchGoingOn, setIsSearchGoingOn] = useState<boolean>(false)
    const [items, setItems] = useState<any>([]);
    const [downloadReportIdObj, setDownloadReportIdObj] = useState<any>({});
    const [allCheckBoxSelected, setAllCheckBoxSelected] = useState<boolean>(false);
    const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
    const pageTitle="MSN Management"

    const resetAll=()=>{
      setIsSaveEnable(false)
      setUpdatedList([])
      setSiteDetails([])
      setFormData({
        serialNumberActive: [],
        serialNumberDecommissioned: [],
        serialNumberFailOver: [],
        serialNumberDetails: [],
        trueUpPeriodStartDate: "",
        trueUpPeriodEndDate: "",
      })
      setItems([])
    }
    useEffect(()=>{
        setFormData({
            serialNumberActive: detailsObj.siteIdDetailsNotDecommissioned || [],
            serialNumberDecommissioned: detailsObj.siteIdDetailsDecommissioned || [],
            serialNumberFailOver: detailsObj.siteIdDetailsFailed || [],
            serialNumberDetails: detailsObj.serialNumberAndCommentDtoList || [],
            trueUpPeriodStartDate: detailsObj.trueUpPeriodStartDate || "",
            trueUpPeriodEndDate: detailsObj.trueUpPeriodEndDate || "",
        })
        let newList:any = detailsObj.serialNumberAndCommentDtoList || []
        let updatedList:ISiteDetails[]=[]
        newList.forEach((data:any)=>{
          let obj:ISiteDetails={
            serialNumber:data.serialNumber,
            status:data.status,
            feedback:data.feedback,
            comments:data.comments,
            commissionedDate: data.commissionedDate ? data.commissionedDate : "",
            decommissionedDate: data.decommissionedDate ? data.decommissionedDate:"",
            internalComments: data.internalComments ? data.internalComments:"" ,
          }
          updatedList.push(obj)
        })
        setSiteDetails(updatedList)
    },[detailsObj])

    useEffect(()=>{
      if(updateDetailFlag){ 
        setIsSearchGoingOn(false);
        setDownloadReportIdObj({});
        setIsSelectAll(false);
        setAllCheckBoxSelected(false);
        resetAll()
        dispatch(loadSiteDetailBySiteId(searchValue))
      }
    },[updateDetailFlag])

    useEffect(()=>{
        const delayDebounceFn = setTimeout(() => {
          if (isSearchGoingOn) {
            setIsSearchGoingOn(false);
            setDownloadReportIdObj({});
            setIsSelectAll(false);
            setAllCheckBoxSelected(false);
            resetAll()
            searchValue && dispatch(loadSiteDetailBySiteId(searchValue))
          }
        }, 750);
        return () => {
            clearTimeout(delayDebounceFn);
        };
      },[searchValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleOnChange=(searchVal:string)=>{
        setSearchValue(searchVal)
    }

    const onChange = (value: string, serialNumber: string, index: number, type: string) => {
        const newItems = [...items];
        newItems[index][type] = value;
        
        if(type==="status"){
          value === siteDetail[index][type] ? 
          newItems[index]["changed"] = false : 
          newItems[index]["changed"] = true

          newItems[index]["status"]==="A" ? 
            newItems[index]["decommissionedDate"] = getUTCTime(siteDetail[index]["decommissionedDate"])
            :newItems[index]["commissionedDate"] = getUTCTime(siteDetail[index]["commissionedDate"])
        }
        if(type==="feedback"){
          value === siteDetail[index][type] ? 
          newItems[index]["feedbackChanged"] = false : 
          newItems[index]["feedbackChanged"] = true
          
        }
        if(type=== "commissionedDate"){
          value === siteDetail[index][type]? 
          newItems[index]["commDateFlag"] = false : 
          newItems[index]["commDateFlag"] = true
        }
        if(type==="decommissionedDate"){
          value === siteDetail[index][type] ? 
          newItems[index]["decommDateFlag"] = false : 
          newItems[index]["decommDateFlag"] = true
        }
        if(type==="internalComments"){
          value === siteDetail[index][type] ? 
          newItems[index]["feedbackChangedInternal"] = false : 
          newItems[index]["feedbackChangedInternal"] = true
          
        }
        if(type==="comments"){
          value === siteDetail[index][type] ? 
          newItems[index]["commentsFlag"] = false : 
          newItems[index]["commentsFlag"] = true
          
        }
        // newItems.forEach((data:IUpdatedList)=>{
        //   if(data.changed || data.feedbackChanged || data.commDateFlag || data.decommDateFlag || data.feedbackChangedInternal || data.commentsFlag)
        //     {
        //       data.commissionedDate=dateFormat(data.commissionedDate)
        //       data.decommissionedDate=dateFormat(data.decommissionedDate)
        //     }
        // })
        if(newItems[index]["comments"] !== siteDetail[index]["comments"] || 
          newItems[index]["feedback"] !== siteDetail[index]["feedback"] ||
          newItems[index]["internalComments"] !== siteDetail[index]["internalComments"] || 
          newItems[index]["status"] !== siteDetail[index]["status"] ||
          dateFormat(getUTCTime(newItems[index]["commissionedDate"])) !== dateFormat(getUTCTime(siteDetail[index]["commissionedDate"])) ||
          dateFormat(getUTCTime(newItems[index]["decommissionedDate"])) !== dateFormat(getUTCTime(siteDetail[index]["decommissionedDate"]))
        ){
          newItems[index]["isUpdated"] = true;
          setItems(newItems);
          return ;
        }else{
          newItems[index]["isUpdated"] = false;
          setItems(newItems);
        }    
    }
    useEffect(()=>{
      let newItems=[...items]
      let newList:IUpdatedList[]=[]
      newItems.forEach((data:IUpdatedList)=>{
        if(data.isUpdated){
          let obj:IUpdatedList=data
          newList.push(obj)
        }
      })
      setUpdatedList(newList)
      
    },[items])

    useEffect(()=>{
      updatedList.length ? setIsSaveEnable(true) : setIsSaveEnable(false)        
    },[updatedList])
      useEffect(()=>{
        if (formData) {
            originalItemsObj = {};
            originalSerialNumberData = {};
            
            const filteredData = formData.serialNumberDetails.filter((i: any) => {
              return i.status !== "R";
            });
            filteredData.forEach((item: any) => {
              const { serialNumber, status, feedback, comments, internalComments } = item;
              originalSerialNumberData[serialNumber] = { status, feedback, comments, internalComments };
              originalItemsObj[serialNumber] = {
                serialNumber,
                status,
                feedback,
                comments,
                changed: false,
                feedbackChanged: false,
                commDecommDate: "",
                commissionedDate: item.commissionedDate ? getUTCTime(item.commissionedDate) : "",
                decommissionedDate: item.decommissionedDate ? getUTCTime(item.decommissionedDate) :"",
                internalComments: item.internalComments ? item.internalComments: "",
                commDecommFlag: false,
                feedbackChangedInternal: false,
                commentsFlag:false,
                isUpdated:false,
                commDateFlag:false,
                decommDateFlag:false,
              };
            });
            
            let arr1=Object.values(originalItemsObj)
            let count = 0;
            const newData:any[] =
              (arr1 && arr1.length &&
                arr1.map((row:any) => {
                  !!downloadReportIdObj[row.serialNumber] && (count += 1);
                  return {
                    ...row,
                    isChecked: !!downloadReportIdObj[row.serialNumber],
                  };
                })) ||
              [];
            isSaveDisabled = true;
            setItems(newData)
            setIsSelectAll(newData.length > 0 && newData.length === count);
        }
    },[formData])

    const onSave= (fromOuterModal = false) => {
      let newList=[...items]
      let payloadList:any[]=[]
      
      newList.forEach((data:any)=>{
        
        if(data.isUpdated){
          let obj:any={
            serialNumber: data.serialNumber,
            status: data.status,
            feedback: data.feedback,
            comments: data.comments,
            changed: data.isUpdated,
            commissionedDate: dateFormat(data.commissionedDate),
            decommissionedDate: dateFormat(data.decommissionedDate),
            internalComments: data.internalComments,

            //feedbackChanged: data.feedbackChanged,
            //commDecommFlag:data.commDecommFlag,
            //commDecommDate: data.commDecommDate,
            //commDateFlag:data.commDateFlag,
            //decommDateFlag:data.decommDateFlag,
          }
          payloadList.push(obj)
        } 
      })
     
      const data = {
        siteId: detailsObj.siteId,
        siteName: detailsObj.siteName || "",
        serialNumberStatusChangeDtoList:payloadList,
      };
      dispatch(updateAdminSiteDetails(data))
    }

    const onClear=()=>{
      let newList=[...siteDetail]
      let resetList:any[]=[]
      newList.forEach((data:ISiteDetails,idx:any)=>{
        let obj={
          serialNumber:data.serialNumber,
          status:data.status,
          feedback:data.feedback,
          comments:data.comments,
          changed:false,
          feedbackChanged: false,
          commDecommDate: "",
          commissionedDate: getUTCTime(data.commissionedDate),//dateFormat(data.commissionedDate),
          decommissionedDate: getUTCTime(data.decommissionedDate),//dateFormat(data.decommissionedDate),
          internalComments: data.internalComments,
          commDecommFlag: false,
          feedbackChangedInternal: false,
          commentsFlag:false,
          isUpdated:false,
          commDateFlag:false,
          decommDateFlag:false,
          isChecked:items[idx]["isChecked"]
        }
        resetList.push(obj)
      })
      setItems(resetList)
      // setDownloadReportIdObj({});
      // setIsSelectAll(false);
      // setAllCheckBoxSelected(false);
    }
    const dateFormat=(dateVal:any)=>{
      let dateStr=new Date(dateVal)
      let finalStr=""
      if(dateVal!==""){
        let year=dateStr.getFullYear()
        let month=''+(dateStr.getMonth()+1)
        let day=''+dateStr.getDate()
        if(month.length<2){
          month='0'+month
        }
        if(day.length<2){
          day='0'+day
        }
        finalStr=`${year}-${month}-${day}`
      }
      return finalStr
    }

    const handleCheckboxChange = (index: number, value: boolean) => {
        const checkedData: any = [...items];
        const newDownloadReportIdObj: any = { ...downloadReportIdObj };
        checkedData[index].isChecked = value;
        if (value) {
          newDownloadReportIdObj[checkedData[index].serialNumber] = checkedData[index].serialNumber;
        } else {
          delete newDownloadReportIdObj[checkedData[index].serialNumber];
        }
        const len = checkedData.filter((i: any) => {
          return i.isChecked;
        }).length;
        
        setIsSelectAll(len === checkedData.length);
        setAllCheckBoxSelected(Object.values(newDownloadReportIdObj).length > 1);
        setItems(checkedData);
        setDownloadReportIdObj(newDownloadReportIdObj);
    };

    const handleAllCheckboxChange = (value: boolean) => {
        const checkedData:any[] = [...items];
        const newDownloadReportIdObj: any = { ...downloadReportIdObj };
        checkedData.map((i: any) => {
          i.isChecked = value;
          if (value) {
            newDownloadReportIdObj[i.serialNumber] = i.serialNumber;
          } else {
            delete newDownloadReportIdObj[i.serialNumber];
          }
          return i;
        });
        setAllCheckBoxSelected(value);
        setIsSelectAll(value);
        setItems(checkedData);
        setDownloadReportIdObj(newDownloadReportIdObj);
      };

      const sendToERP=(msnList:any)=>{
        dispatch(triggerSendToERP(msnList));
      }
    
    let STATUS_COLUMNS: any = [
        {
            headerName: "",
            field: "",
            sortable: false,
            cellRenderer: <TableCheckBox handleCheckboxChange={handleCheckboxChange}></TableCheckBox>,
            filter: { show: items.length > 0, type: "selectCheckbox" },
            filterRenderer: (
              <TableFilterCheckbox
                handleAllCheckboxChange={handleAllCheckboxChange}
                isSelectAll={isSelectAll}
              />
            ),
            width:"5rem"
        },
        {
          headerName: "Serial Number",
          field: "serialNumber",
          filter: { show: false },
          width:"3rem"
        },
        {
          headerName: "Status",
          width: "5%",
          field: "status",
          filter: { show: false },
          cellRenderer: <MSNDateRenderer type="status" onChange={onChange} />,
        },
        {
          headerName: "Feedback",
          renderer: <FeedBackHeaderRenderer />,
          field: "feedback",
          filter: { show: false },
          cellRenderer: (
            <MSNDateRenderer
              type="feedback"
              feedbackOptions={FEEDBACK_OPTIONS}
              onChange={onChange}
            />
          ),
        },
        {
            headerName: "Comissioned Date",
            renderer: <FeedBackHeaderRenderer name="Commissioned Date" />,
            field: "commissionedDate",
            filter: { show: false },
            cellRenderer: (
              <MSNDateRenderer
                type="datepicker"
                onChange={onChange}
                trueUpStartDate={"2024-06-09"}
                trueUpEndDate={"2024-06-29"}
                dateRestriction={false}
                datePickerType="commissionedDate"
              />
            ),
            width: "10rem",
          },
          {
            headerName: "Decomissioned Date",
            renderer: <FeedBackHeaderRenderer name="Decommissioned Date" />,
            field: "decommissionedDate",
            filter: { show: false },
            cellRenderer: (
              <MSNDateRenderer
                type="datepicker"
                onChange={onChange}
                trueUpStartDate={"2024-06-09"}
                trueUpEndDate={"2024-06-29"}
                dateRestriction={false}
                datePickerType="decommissionedDate"
              />
            ),
            width: "10rem",
          },
          {
            headerName: "Comments",
            renderer: <FeedBackHeaderRenderer name="Comments" required={false} />,
            field: "internalComments",
            filter: { show: false },
            cellRenderer: <MSNDateRenderer type="textarea" onChange={onChange} />,
            width: "10rem",
          },
    ];
    return(
        <>
          <ClayLayout.ContainerFluid view size={false}>
            <ClayLayout.Row justify="start" className="align-items-center">
              <ClayLayout.Col className="d-flex align-items-center">
                  <PageTitle title={pageTitle} />
              </ClayLayout.Col>
            </ClayLayout.Row>
            <ClayLayout.Row>
                <ClayCard className="m-3 w-100">
                    <ClayCard.Body className="p-4">
                        <ClayLayout.Row className="mb-4">
                            <ClayLayout.Col size={8}>
                                <div className="serial-container">
                                <div className="serial-label">Support Site ID: &emsp;</div>
                                    <ClayInput
                                        type="text"
                                        id={"supportSiteId"}
                                        placeholder={"Search With Support site Id"}
                                        value={searchValue}
                                        onChange={(event) => {
                                        setIsSearchGoingOn(true);
                                        handleOnChange(event.target.value)
                                    }}
                                    />
                                </div>
                            </ClayLayout.Col>
                        </ClayLayout.Row>
                        {searchValue.length >0 &&
                        <>
                          <Table
                              definitions={STATUS_COLUMNS}
                              dataSource={items}
                              borderedColumns={false}
                              handleCheckboxChange={handleCheckboxChange}
                              borderless={true}
                              headVerticalAlignment={"middle"}
                              headingNoWrap={true}
                              hover={false}
                              noWrap={true}
                              responsive={true}
                              responsiveSize={"sm"}
                              striped={true}
                              tableVerticalAlignment={"middle"}
                              
                          /> 
                          <ClayLayout.Row className="mt-4">
                            <ClayLayout.Col className="d-flex align-items-center flex-row-reverse">
                              <ClayButton 
                                className="ml-3"
                                displayType="primary" 
                                type="button" 
                                onClick={()=>onSave(false)}
                                disabled={!isSaveEnable}
                              >
                                Save
                              </ClayButton>
                              <ClayButton 
                                className="ml-3"
                                displayType="secondary" 
                                type="button" 
                                onClick={onClear}
                              >
                                Clear
                              </ClayButton>
                              { isBillable &&
                                <ClayButton 
                                className="ml-3"
                                displayType="primary" 
                                type="button" 
                                disabled={Object.values(downloadReportIdObj).length===0}
                                onClick={()=>{
                                  sendToERP(Object.values(downloadReportIdObj))
                                }}
                                >
                                  Send to ERP
                                </ClayButton>
                              }
                                
                            </ClayLayout.Col>
                          </ClayLayout.Row>
                        </>}
                    </ClayCard.Body>
                </ClayCard>
            </ClayLayout.Row>
          </ClayLayout.ContainerFluid>
        </>
    )
}

export default MSNManagemet;
