import ClayDropDown from "@clayui/drop-down";
import ClayIcon from "@clayui/icon";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import NavBar from "@components/navbar/NavBar";
import { ROUTE_PATHS, checkIfSCRTAccess } from "@constants";
import { ISignedinUser } from "@interfaces";
//import { useOktaAuth } from "@okta/okta-react";
import {
  getErrorSignedInUser,
  getNoSiteAccessDetails,
  getSignedInUser,
  loadSignedInUserDetails,
} from "@store/userDetails/slice";
import { GET_USER_NAME_OKTA, isAuditRepoAdmin, isSuperAdmin } from "@utils";
import isEmpty from "lodash.isempty";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Alert from "./../../../components/Alert";
import "./Header.scss";

function Header(props: any) {
  const { showNavbar = true } = props;
  //const { oktaAuth, authState } = useOktaAuth() || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const noSiteAccess = useSelector(getNoSiteAccessDetails);

  const envVars = useSelector(getEnvVars);
  const userDetails: ISignedinUser = useSelector(getSignedInUser);
  const errorLoggedIn = useSelector(getErrorSignedInUser);
  const location = useLocation();
  let hideUserDetailsList = [
    ROUTE_PATHS.OKTA_LOGIN + "",
    ROUTE_PATHS.LOGOUT + "",
    ROUTE_PATHS.OKTA_REDIRECT + "",
    ROUTE_PATHS.NO_ACCESS + "",
    //ROUTE_PATHS.ECX_REDIRECT + "",
  ];
  if (!checkIfSCRTAccess()) {
    hideUserDetailsList.push(ROUTE_PATHS.REPO_LOGS);
  }
  const hideUserDetails = hideUserDetailsList.includes(location.pathname);
  const isRepoLogsRedirect = location.pathname === ROUTE_PATHS.REPO_LOGS;

  const registerOktaUser: string | undefined = envVars.REGISTRATION_URL;

  const login = async () => {
    window.location.href = "/login";
  };

  const logout = async (event: any) => {
    if (event) {
      event.preventDefault();
    }
    window.location.href = ROUTE_PATHS.LOGOUT;
  };

  const handleOptionClick = (url: any) => {
    window.location.href = url;
  };

  let isSignedIn: any = location.pathname !== ROUTE_PATHS.HOME;

  useEffect(() => {
    const redirect = JSON.parse(localStorage.getItem("scrt-local") || "{}");
    if (
      !isRepoLogsRedirect &&
      redirect?.redirect &&
      ![redirect.redirect, ROUTE_PATHS.LOGOUT].includes(location.pathname)
    ) {
      window.location.href = redirect.redirect;
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isSignedIn && !hideUserDetails) {
      dispatch(loadSignedInUserDetails());
    }
  }, [isSignedIn, dispatch]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const location = history.location.pathname || "";
    if (errorLoggedIn && location !== ROUTE_PATHS.LOGOUT && location !== ROUTE_PATHS.NO_ACCESS) {
      window.location.href = ROUTE_PATHS.NO_ACCESS;
    }
  }, [errorLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userDetails && !isEmpty(userDetails)) {
      const scrtLocalDetails = JSON.parse(localStorage.getItem("scrt-local") || "{}");
      delete scrtLocalDetails.redirect;
      localStorage.setItem("scrt-local", JSON.stringify({ ...scrtLocalDetails, ...userDetails }));
    }
  }, [userDetails]);

  useEffect(() => {
    if (!isRepoLogsRedirect && !!noSiteAccess) {
      window.location.href = ROUTE_PATHS.OKTA_REDIRECT;
    }
  }, [noSiteAccess]); // eslint-disable-line react-hooks/exhaustive-deps

  const [active, setActive] = useState(false);

  return (
    <React.Fragment>
      <header className="brdcm-standard-header brdcm-fade-in">
        <div className="brdcm-header-top">
          <div
            className={`brdcm-container brdcm-navigation-primary ${
              isRepoLogsRedirect && !checkIfSCRTAccess() ? "header-border" : ""
            }`}
          >
            <div className="brdcm-branding brdcm-tablet-hidden">
              <a
                tabIndex={1}
                className="brdcm-brand-logo"
                target="_blank"
                rel="noreferrer"
                href="https://broadcom.com"
              >
                {" "}
              </a>
            </div>

            <div className="brdcm-primary-nav-container">
              <div className="brdcm-primary-menu brdcm-tablet-visible pl-4 pl-lg-0">
                <button>
                  <div></div>
                </button>
                <div className="brdcm-primary-menu-label">Menu</div>
              </div>

              <div className="brdcm-branding d-block d-lg-none pl-4 pl-lg-0">
                <a
                  tabIndex={1}
                  className="brdcm-brand-logo"
                  target="_blank"
                  rel="noreferrer"
                  href="https://broadcom.com"
                >
                  {" "}
                </a>
              </div>

              <div className="brdcm-primary-nav-links-wrapper brdcm-fade-in brdcm-tablet-hidden">
                <ul className="brdcm-primary-nav-links">
                  <li className="brdcm-primary-nav-item">
                    <a className="brdcm-primary-nav-link" href="https://www.broadcom.com/products/">
                      Products
                    </a>
                  </li>
                  <li className="brdcm-primary-nav-item">
                    <a className="brdcm-primary-nav-link" href="https://www.broadcom.com/solutions">
                      Solutions
                    </a>
                  </li>
                  <li className="brdcm-primary-nav-item">
                    <a className="brdcm-primary-nav-link" href="https://www.broadcom.com/support">
                      Support and Services
                    </a>
                  </li>
                  <li className="brdcm-primary-nav-item">
                    <a
                      className="brdcm-primary-nav-link"
                      href="https://www.broadcom.com/company/about-us/"
                    >
                      Company
                    </a>
                  </li>
                  <li className="brdcm-primary-nav-item">
                    <a
                      className="brdcm-primary-nav-link"
                      href="https://www.broadcom.com/how-to-buy/#sales"
                    >
                      How To Buy
                    </a>
                  </li>
                </ul>
              </div>

              <div className="brdcm-nav-logged-in-details pr-md-4">
                <div className="brdcm-profile-container brdcm-modal-dropdown-wrapper">
                  {isSignedIn ? (
                    <ClayDropDown
                      trigger={
                        <button className="btn btn-primary brdcm-login-register-btn">
                          <strong>{userDetails?.name || GET_USER_NAME_OKTA()} </strong>
                        </button>
                      }
                      active={active}
                      onActiveChange={setActive}
                      menuElementAttrs={{
                        className: "my-custom-dropdown-menu",
                        containerProps: {
                          className: "dropdown-menu-react-portal-div",
                          id: "dropdownMenuReactPortalDiv",
                        },
                      }}
                    >
                      <ClayDropDown.ItemList>
                        <ClayDropDown.Group>
                          {/* {isSuperAdmin(userDetails?.role) && (
                            <ClayDropDown.Item
                              href={""}
                              onClick={() => handleOptionClick(ROUTE_PATHS.MANAGE)}
                            >
                              <ClayIcon symbol="message" spritemap={claySpriteImagePath()} />
                              &nbsp;{"Broadcast Message"}
                            </ClayDropDown.Item>
                          )} */}
                          {/* {isSuperAdmin(userDetails?.role) && (
                            <ClayDropDown.Item
                              href={""}
                              onClick={() => handleOptionClick(ROUTE_PATHS.SITEMAPPING)}
                            >
                              <ClayIcon symbol="diagram" spritemap={claySpriteImagePath()} />
                              &nbsp;{"Site Mapping"}
                            </ClayDropDown.Item>
                          )} */}
                          {(isSuperAdmin(userDetails?.role) || isAuditRepoAdmin(userDetails?.role)) && (
                            <ClayDropDown.Item
                              href={""}
                              onClick={() => handleOptionClick(ROUTE_PATHS.AUDIT_MAPPING)}
                            >
                              <ClayIcon symbol="share-alt" spritemap={claySpriteImagePath()} />
                              &nbsp;{"Audit Mapping"}
                            </ClayDropDown.Item>
                          )}
                          {!hideUserDetails && (
                            <ClayDropDown.Item
                              href={""}
                              onClick={() => handleOptionClick(ROUTE_PATHS.PROFILE)}
                            >
                              <ClayIcon symbol="user" spritemap={claySpriteImagePath()} />
                              &nbsp;{"Profile"}
                            </ClayDropDown.Item>
                          )}
                          {userDetails?.scrtInternal && (
                            <ClayDropDown.Item
                              href={""}
                              onClick={() => handleOptionClick(ROUTE_PATHS.REPO_LOGS)}
                            >
                              <ClayIcon symbol="blogs" spritemap={claySpriteImagePath()} />
                              &nbsp;{"Repository Logs"}
                            </ClayDropDown.Item>
                          )}
                          <ClayDropDown.Item href={""} onClick={logout}>
                            <ClayIcon symbol="logout" spritemap={claySpriteImagePath()} />
                            &nbsp;{"Sign Out"}
                          </ClayDropDown.Item>
                        </ClayDropDown.Group>
                      </ClayDropDown.ItemList>
                    </ClayDropDown>
                  ) : (
                    <>
                      <a
                        className="btn btn-secondary brdcm-login-register-btn m-1"
                        href={registerOktaUser}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Register
                      </a>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                      <a className="btn btn-primary brdcm-login-register-btn" onClick={login}>
                        Login
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {location.pathname === "/" && (
        <div className="">
          <Alert
            report={{
              type: "warning",
              message: `<div>
              <p>If you're facing website flashing issues while logging into SCRT Portal, kindly follow below steps:</p>
            <ol>
              <li>Go to your browser settings.</li>
              <li>Clear the cookie cache completely. In case 24 hours is selected by default, change the drop down value to 'All Time' and then clear your browser cache.</li>
              <li>If you're still facing the issue, kindly contact <a href=${envVars.REACT_APP_SUPPORT_URL} class="support-link" target="_blank" rel="noreferrer"><b>Broadcom Support</b></a>.</li>
            </ol>
              </div>`,
            }}
          />{" "}
        </div>
      )}
      {isSignedIn && showNavbar && <NavBar />}
    </React.Fragment>
  );
}

export default Header;
