import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import { axiosApi } from "@common-services/redux-helper/middleware/axios-api";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN, ROLE_USER,SUPER_ADMIN_ROLE, ROLE_AUDIT_REPO_ADMIN, AUDIT_REPO_ROLE, GTO_MSD_SUPPORT_ROLE, ROLE_GTO_MSD_SUPPORT } from "@constants";
import { createSlice } from "@reduxjs/toolkit";
import { GET_USER_NAME_OKTA } from "@utils";
import isEmpty from "lodash.isempty";

import { USER_DETAILS_URL } from "./constants";

const slice = createSlice({
  name: "userDetails",
  initialState: {
    userList: [],
    signedInUser: {},
    errorLoggedIn: false,
    userSiteDetails: [],
    adminSiteDetails: [],
    noSiteAccess: false,
  },
  reducers: {
    userListReceived: (userDetails, action) => {
      if (action.payload) {
        userDetails.userList = action.payload.data.list;
      }
    },
    signedInUserDetails: (userDetails, action) => {
      const userData = action?.payload?.data || {};
      userDetails.noSiteAccess = false;
      if (!!action?.payload?.success || !isEmpty(userData)) {
        const email = userData?.userDetailsVO?.emailAddress || "";
        const scrtUserSites = userData?.userSiteDetails || [];
        const scrtSiteUser =
          scrtUserSites && scrtUserSites.length
            ? scrtUserSites.some((i) => i.scrtAccess || i.scrtAdmin)
            : false;
        if (!email || !scrtSiteUser) {
          userDetails.noSiteAccess = true;
          userDetails.errorLoggedIn = false;
          return;
        }
        let name =
          userData?.userDetailsVO?.firstName && userData?.userDetailsVO?.lastName
            ? userData?.userDetailsVO?.firstName + " " + userData?.userDetailsVO?.lastName
            : !userData?.userDetailsVO?.firstName || !userData?.userDetailsVO?.lastName
            ? GET_USER_NAME_OKTA()
            : email
            ? email
            : "";
        
        const isSuperAdmin =
          scrtUserSites && scrtUserSites.length
            ? scrtUserSites.some((i) => i.role === SUPER_ADMIN_ROLE )
            : false;
        const isAuditRepoAdmin= (!isSuperAdmin) ?
            scrtUserSites && scrtUserSites.length && scrtUserSites.some((i) => i.role === AUDIT_REPO_ROLE)
            : false;
        const isGTOMSDSupport= (!isSuperAdmin && !isAuditRepoAdmin) ?
            scrtUserSites && scrtUserSites.length && scrtUserSites.some((i) => i.role === GTO_MSD_SUPPORT_ROLE)
            : false;
        const isAdmin = (!isSuperAdmin && !isAuditRepoAdmin && !isGTOMSDSupport)
          ? scrtUserSites && scrtUserSites.length && scrtUserSites.some((i) => i.scrtAdmin)
          : false;

        let role = "";
        if (isSuperAdmin) {
          role = ROLE_SUPER_ADMIN;
        } else if(isAuditRepoAdmin){
          role = ROLE_AUDIT_REPO_ADMIN;
        }else if(isGTOMSDSupport){
          role = ROLE_GTO_MSD_SUPPORT;
        }else if (isAdmin) {
          role = ROLE_ADMIN;
        } else {
          role = ROLE_USER;
        }

        const subscribeAnnouncement = scrtUserSites[0]["announcement"] === "Y" ? true : false;
        const scrtInternal = userData ? userData.scrtInternal : false;
        if (action.payload) {
          userDetails.signedInUser = {
            email,
            name,
            role,
            announcement: subscribeAnnouncement,
            scrtInternal,
          };
        }

        // store user site details in store
        userDetails.userSiteDetails =
          userData?.userSiteDetails?.filter((i) => i.scrtAccess || i.scrtAdmin) || [];
        userDetails.adminSiteDetails = userData?.listOfAdminUserObjects || [];
      } else {
        userDetails.errorLoggedIn = true;
      }
    },
    userProfileUpdated: (userDetails, action) => {
      if (action.payload) {
        userDetails.signedInUser = {
          ...userDetails.signedInUser,
          announcement: action.payload.data.announcement === "Y",
        };
      }
    },
    signedInUserError: (state) => {
      state.errorLoggedIn = true;
    },
  },
});

export const { userListReceived, signedInUserDetails, userProfileUpdated, signedInUserError } =
  slice.actions;

export const updateUserDetails = (announcement) => (dispatch, getState) => {
  const signedInUser = getSignedInUser(getState());
  return dispatch(
    apiCallBegan({
      url: USER_DETAILS_URL.UPDATE_USER_PROFILE,
      method: "PUT",
      data: {
        name: signedInUser.name,
        announcement: announcement ? "Y" : "N",
      },
      onSuccess: userProfileUpdated.type,
    }),
  );
};

export const loadSignedInUserDetails = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: USER_DETAILS_URL.GET_SIGNED_IN_USER_DETAILS,
      data: {},
      method: "POST",
      onSuccess: signedInUserDetails.type,
      onError: signedInUserDetails.type,
    }),
  );
};

export const addUserEvent = (eventType) => (dispatch) => {
  return axiosApi({
    url: USER_DETAILS_URL.USER_EVENT,
    method: "POST",
    data: {
      event: eventType,
    },
  });
};

export const getUserEvent = (state) => {
  return state.userDetails.userEvent;
};

export const getUserDetails = (state) => state.userDetails.userList;
export const getSignedInUser = (state) => state.userDetails.signedInUser;
export const getErrorSignedInUser = (state) => state.userDetails.errorLoggedIn;
export const getUserSiteDetails = (state) => state.userDetails.userSiteDetails;
export const getAdminUserSiteDetails = (state) => state.userDetails.adminSiteDetails;
export const getNoSiteAccessDetails = (state) => state.userDetails.noSiteAccess;

// reducer
export const userDetailsReducer = slice.reducer;
