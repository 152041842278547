import ReminderLogs from "@components/screens/repository/logs/ReminderLogs";
//import { IconInputboxComponent } from "@common-components"
const ReminderManagement=()=>{    
    return (
        <>
            
            {/* <IconInputboxComponent
                // name="search"
                // placeholder={currentTab === PRODUCT_DOWNLOADS_LIST_TABS.PRODUCTS ? "Search by Release or Language" : "Search by Product Name"}
                // onKeyDown={}
                onChange={(e:any) => {
                    console.log("Search",e.target.value)
                    // setIsSearchGoingOn(true);
                    // handleSearchBoxChange(val.currentTarget.value)
                }}
                // //onBlur={}
                // value={searchString}
                // autoComplete={"off"}
                // showCrossIcon={true}
                // crossClick={() => {
                //     setIsSearchGoingOn(true);
                //     handleSearchBoxChange("")
                // }}
            /> */}
            <ReminderLogs/>
        </>
    )
}

export default ReminderManagement;