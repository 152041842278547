import ClayButton from "@clayui/button";
import ClayCard from "@clayui/card";
import ClayForm from "@clayui/form";
import ClayIcon from "@clayui/icon";
import ClayLabel from "@clayui/label";
import ClayLayout from "@clayui/layout";
import { NavTabs, PageTitle } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import { ROLE_USER, ROUTE_PATHS } from "@constants";
import { EDIT_SITE_DETAILS_TABS, EDIT_SITE_DETAILS_TAB_LIST } from "@store/siteDetails/constants";
import { getSiteDetail, loadSiteDetailBySiteId } from "@store/siteDetails/slice";
import { GET_USER_ROLE, getUserTime, isAuditRepoAdmin, isGTOMSDSupport } from "@utils";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as Yup from "yup";

import ScrtReports from "./ScrtReports";
import SiteModal from "./SiteModal";
import SitesUser from "./SitesUser";
import "./sites.scss";
import { getSignedInUser } from "@store/userDetails/slice";
import { ISignedinUser } from "@interfaces";

function SitesDetail(props: any) {
  const pageTitle = `Edit Site`;
  const history = useHistory();
  const params: any = useParams();
  const dispatch = useDispatch();
  const form = useRef(null);

  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";
  const signedInUser: ISignedinUser = useSelector(getSignedInUser);
  const auditRepoAdmin = isAuditRepoAdmin(signedInUser?.role)
  const gtoMsdSupport = isGTOMSDSupport(signedInUser?.role)

  useEffect(() => {
    if (isOktaEnabled && isValidUser !== "true") {
      window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
    } else if (GET_USER_ROLE() === ROLE_USER) {
      window.location.href = ROUTE_PATHS.NO_ACCESS;
    }
    dispatch(loadSiteDetailBySiteId(params.id || ""));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const siteDetail = useSelector(getSiteDetail);

  const [formData, setFormData] = useState({
    serialNumberActive: siteDetail.siteIdDetailsNotDecommissioned || [],
    serialNumberDecommissioned: siteDetail.siteIdDetailsDecommissioned || [],
    serialNumberFailOver: siteDetail.siteIdDetailsFailed || [],
    serialNumberDetails: siteDetail.serialNumberAndCommentDtoList || [],
    trueUpPeriodStartDate: siteDetail.trueUpPeriodStartDate || "",
    trueUpPeriodEndDate: siteDetail.trueUpPeriodEndDate || "",
  });

  useEffect(() => {
    if (siteDetail) {
      setFormData({
        serialNumberActive: siteDetail.siteIdDetailsNotDecommissioned || [],
        serialNumberDecommissioned: siteDetail.siteIdDetailsDecommissioned || [],
        serialNumberFailOver: siteDetail.siteIdDetailsFailed || [],
        serialNumberDetails: siteDetail.serialNumberAndCommentDtoList || [],
        trueUpPeriodStartDate: siteDetail.trueUpPeriodStartDate || "",
        trueUpPeriodEndDate: siteDetail.trueUpPeriodEndDate || "",
      });
    }
  }, [siteDetail]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentTab, setCurrentTab] = useState(EDIT_SITE_DETAILS_TABS.scrtReports);

  const getTabComponent = () => {
    switch (currentTab) {
      case EDIT_SITE_DETAILS_TABS.scrtReports:
        return <ScrtReports siteId={params?.id || ""} />;
      case EDIT_SITE_DETAILS_TABS.users:
        return <SitesUser siteId={params?.id || ""} />;
    }
  };

  const handleTabChanged = (navPath: any) => {
    // Util.updateQueryParam("tab", navPath, true); HAVE TO IMPLEMENT TAB CHANGE URL
    setCurrentTab(navPath);
  };
  const onSubmit = (e: any) => {
    history.push(ROUTE_PATHS.SITES);
  };

  const onBack = () => {
    window.location.href = ROUTE_PATHS.SITES;
  };

  const navTabs = EDIT_SITE_DETAILS_TAB_LIST;

  const validationSchema = Yup.object().shape({});

  const onModalClose = (flag: boolean) => {
    document.body.style.overflow = "visible";
    setIsModalOpen(flag);
  };

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row justify="start" className="align-items-center">
          <ClayLayout.Col className="d-flex align-items-center">
            <ClayIcon
              symbol="order-arrow-left"
              className="pe-cursor"
              fontSize={"30px"}
              spritemap={claySpriteImagePath()}
              onClick={onBack}
            />
            &emsp;
            <PageTitle title={pageTitle} />
          </ClayLayout.Col>
        </ClayLayout.Row>
        <ClayLayout.Row>
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="p-4">
              <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                innerRef={form}
              >
                {(formik) => (
                  <ClayForm onSubmit={formik.handleSubmit} className="m-4 mw-100">
                    <ClayLayout.Row justify="start">
                      <ClayLayout.Col xs={12} md={6} lg={3}>
                        <div>
                          <label>Site Name</label>
                          <div>
                            {siteDetail.siteName}
                            {params.id ? ` (${params.id})` : ""}
                          </div>
                        </div>
                      </ClayLayout.Col>
                      <ClayLayout.Col xs={12} md={6} lg={3} className="mt3">
                        <div>
                          <label>Updated</label>
                          <div>{getUserTime(siteDetail.updatedDate)}</div>
                        </div>
                      </ClayLayout.Col>
                      <ClayLayout.Col xs={12} md={6} lg={3} className="mt3">
                        <div>
                          <label>Created</label>
                          <div>{getUserTime(siteDetail.createdDate)}</div>
                        </div>
                      </ClayLayout.Col>
                      <ClayLayout.Col xs={12} md={6} lg={3} className="mt3">
                        <div>
                          <label>Updated By</label>
                          <div>{siteDetail.updatedBy}</div>
                        </div>
                      </ClayLayout.Col>
                      {/* <ClayLayout.Col xs={12} md={6} lg={3}>
                        <div className="mt-4">
                          <label>Created By</label>
                          <div>{siteDetail.createdBy}</div>
                        </div>
                      </ClayLayout.Col> */}
                    </ClayLayout.Row>
                    <hr></hr>
                    <ClayLayout.Row className="mt-3">
                      <ClayLayout.Col xs={12} md={12} lg={12}>
                        <h2>Serial Number</h2>
                      </ClayLayout.Col>
                      <ClayLayout.Col xs={12} md={3} lg={3}>
                        <div>
                          <label>Active</label>
                          <div>
                            {formData.serialNumberActive.map((tag: any) => (
                              <ClayLabel
                                key={tag}
                                className="ecx-tag-label"
                                displayType="secondary"
                                spritemap={claySpriteImagePath()}
                              >
                                {tag}
                              </ClayLabel>
                            ))}
                          </div>
                        </div>
                      </ClayLayout.Col>
                      <ClayLayout.Col xs={12} md={3} lg={3}>
                        <div>
                          <label>Decommissioned</label>
                          <div>
                            {formData.serialNumberDecommissioned.map((tag: any) => (
                              <ClayLabel
                                key={tag}
                                className="ecx-tag-label"
                                displayType="secondary"
                                spritemap={claySpriteImagePath()}
                              >
                                {tag}
                              </ClayLabel>
                            ))}
                          </div>
                        </div>
                      </ClayLayout.Col>
                      <ClayLayout.Col xs={12} md={3} lg={3}>
                        <div>
                          <label>DR/Failover/Swapped Machine</label>
                          <div>
                            {formData.serialNumberFailOver.map((tag: any) => (
                              <ClayLabel
                                key={tag}
                                className="ecx-tag-label"
                                displayType="secondary"
                                spritemap={claySpriteImagePath()}
                              >
                                {tag}
                              </ClayLabel>
                            ))}
                          </div>
                        </div>
                      </ClayLayout.Col>
                      <ClayLayout.Col xs={12} md={3} lg={3} className="mt3">
                        {
                          (!auditRepoAdmin && !gtoMsdSupport) && 
                          <ClayButton
                            type="button"
                            onClick={() => setIsModalOpen(true)}
                            displayType={"secondary"}
                            style={{ fontSize: "small" }}
                          >
                            Modify
                          </ClayButton>
                        }
                        
                      </ClayLayout.Col>
                    </ClayLayout.Row>
                  </ClayForm>
                )}
              </Formik>
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>

        <ClayLayout.Row className={"mt-4"}>
          <ClayLayout.Col size={6}>
            <NavTabs
              tabList={navTabs}
              disableNav="true"
              defaultTab={currentTab}
              tabChanged={handleTabChanged}
            />
          </ClayLayout.Col>
        </ClayLayout.Row>

        {getTabComponent()}
      </ClayLayout.ContainerFluid>
      {isModalOpen && (
        <SiteModal
          isModalOpen={isModalOpen}
          setIsModalOpen={onModalClose}
          formData={formData}
          setFormData={setFormData}
          siteDetail={siteDetail}
        />
      )}
    </React.Fragment>
  );
}

export default SitesDetail;
