import ClayButton from "@clayui/button";
import ClayCard from "@clayui/card";
import ClayForm from "@clayui/form";
import ClayIcon from "@clayui/icon";
import ClayLayout from "@clayui/layout";
import { ConfirmSendModal, FormControl, PageTitle } from "@common-components";
import Sprite from "@common-images/clay/sprite.svg";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { RICH_TEXT_MODULES, ROLE_SUPER_ADMIN, ROUTE_PATHS } from "@constants";
import { IAlertMessage, IBroadcaseMessage } from "@interfaces";
import {
  getEmailUserList,
  getEmailUserListPagination,
  getEnterpriseList,
  getEnterprises,
  getSendEmailResponse,
  handleUserListPagination,
  loadEmailUserList,
  onSendEmailResponse,
  sendEmail,
} from "@store/manage/slice";
import { GET_USER_ROLE, formatRichText } from "@utils";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { IBroadcaseMessageTo, IBroadcaseMessageToObj } from "utils/interfaces/broadcaseMessage";
import * as Yup from "yup";

import Alert from "./../../Alert";
import "./manage.scss";

let selectedEmailObj: IBroadcaseMessageToObj = {};
let userListEmailObj: IBroadcaseMessageToObj = {};
function Manage(props: any) {
  const [sendAlerts, setSendAlerts] = useState([] as IAlertMessage[]);
  const [selectAll, setSelectAll] = useState(false);
  const pageTitle = `Broadcast Message`;
  const dispatch = useDispatch();
  const form = useRef(null);
  const paginationInfo = useSelector(getEmailUserListPagination);

  const emailResponse = useSelector(getSendEmailResponse);
  const [formData, setFormData] = useState<IBroadcaseMessage>({
    from: "",
    to: [],
    subject: "",
    message: "",
    sendToAll: false,
    searchValue: "",
    enterprise: [],
  });
  const [selectedEmail, setSelectedEmail] = useState<IBroadcaseMessageTo[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [emailUserList, setEmailUserList] = useState<IBroadcaseMessageTo[]>([]);
  const [searchString, setSearchString] = useState("");
  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";

  const [enterpriseFilterList, setEnterpriseFilterList] = useState([]);
  const [selectAllEnterpriseFilter, setSelectAllEnterpriseFilter] = useState(false);

  const stateEmailUserList = useSelector(getEmailUserList);
  const enterpriseList = useSelector(getEnterpriseList);

  useEffect(() => {
    if (isOktaEnabled && isValidUser !== "true") {
      window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
    } else if (GET_USER_ROLE() !== ROLE_SUPER_ADMIN) {
      window.location.href = ROUTE_PATHS.NO_ACCESS;
    }
    dispatch(getEnterprises());
    dispatch(onSendEmailResponse({}))
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    userListEmailObj = {};
    let isSelectAllCount = 0;
    stateEmailUserList.forEach((user: any) => {
      selectedEmailObj[user.userId] &&
        selectedEmailObj[user.userId].checked &&
        (isSelectAllCount += 1);
      userListEmailObj[user.userId] = {
        id: user.userId,
        label: user.userId,
        checked: selectedEmailObj[user.userId] ? selectedEmailObj[user.userId].checked : false,
      };
    });
    setEmailUserList([...Object.values(userListEmailObj)]);
  }, [stateEmailUserList]);

  useEffect(() => {
    const filters: any =
      (enterpriseList &&
        enterpriseList.length &&
        enterpriseList.map((i: any, idx: number) => {
          return { label: i, value: i, checked: false };
        })) ||
      [];
    setEnterpriseFilterList(filters);
  }, [enterpriseList]);

  const onFilterCheckboxChange = (index: number, value: boolean) => {
    const filters: any = [...enterpriseFilterList];
    filters[index].checked = value;
    setEnterpriseFilterList(filters);
    setSelectAllEnterpriseFilter(
      filters.length === filters.filter((item: any) => item.checked).length,
    );
    searchUsers(searchString);
  };

  const handleSelectAllEnterpriceFilter = (value: boolean) => {
    const filters: any = [...enterpriseFilterList];
    filters.forEach((item: any) => {
      item.checked = value;
    });
    setEnterpriseFilterList(filters);
    setSelectAllEnterpriseFilter(value);
    searchUsers(searchString);
  };

  useEffect(() => {
    if (Object.keys(emailResponse).length > 0) {
      setSendAlerts([
        {
          type: emailResponse.success ? "success" : "error",
          message: emailResponse.success
            ? "Email sent successfully"
            : "Something went wrong. Please try again later.",
        },
      ]);
      handleModalClose();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }else{
      setSendAlerts([])
    }
  }, [emailResponse]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchUsers(searchString);
    }, 750);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchString]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleMultiSelectChange = (checked: boolean, item: IBroadcaseMessageTo) => {
    if (selectedEmailObj && !selectedEmailObj[item.id] && checked && !selectAll) {
      selectedEmailObj[item.id] = item;
      selectedEmailObj[item.id]["checked"] = checked;
    } else if (selectAll && !checked) {
      delete selectedEmailObj["-1"];
      setSelectAll(false);
    } else {
      delete selectedEmailObj[item.id];
    }
    userListEmailObj[item.id] && (userListEmailObj[item.id].checked = checked);
    const listEmail: IBroadcaseMessageTo[] = Object.values(selectedEmailObj);
    const newUserListEmailList = [...Object.values(userListEmailObj)];
    setFormData({ ...formData, to: listEmail.map((obj) => obj.label) });
    setSelectedEmail(listEmail);
    setEmailUserList(newUserListEmailList);
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const newUserListEmailObj = Object.values(userListEmailObj);
      newUserListEmailObj.forEach((obj) => {
        obj.checked = false;
        return obj;
      });
      setEmailUserList([...newUserListEmailObj]);
      selectedEmailObj = { "-1": { id: "-1", label: "All users", checked: true } };
      setSelectedEmail([{ id: "-1", label: "All users", checked: true }]);
      setFormData({ ...formData, to: ["ALL"], sendToAll: true });
    } else {
      selectedEmailObj = {};
      setSelectedEmail([]);
      setFormData({ ...formData, to: [], sendToAll: false });
    }
    setSelectAll(checked);
  };

  const searchUsers = (searchValue: string) => {
    let filters: any = enterpriseFilterList
      .filter((item: any) => item.checked)
      .map((item: any) => item.label);
    setFormData({ ...formData, searchValue, enterprise: filters.length > 0 ? filters : [""] });
    dispatch(loadEmailUserList(searchValue, filters.length > 0 ? filters : [""]));
  };

  const handleConfirmSend = () => {
    document.body.style.overflow = "visible";
    const message = formData["message"].trim();
    const formatMessage = formatRichText(message);
    const newFormData = { ...formData };
    newFormData["message"] = formatMessage;
    dispatch(sendEmail(newFormData));
    handleModalClose();
    setSelectAll(false);
    setSelectedEmail([]);
    setSearchString("");
    setEnterpriseFilterList([]);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setFormData({
      from: "",
      to: [],
      subject: "",
      message: "",
      sendToAll: false,
      searchValue: "",
      enterprise: [],
    });
    selectedEmailObj = {};
    Object.values(userListEmailObj).forEach((obj) => {
      obj.checked = false;
      return obj;
    });
  };

  const handleUploadAlertClose = () => {
    setSendAlerts([]);
  };

  const handlePageChange = (paginationInfo: any) => {
    dispatch(handleUserListPagination(paginationInfo));
    dispatch(loadEmailUserList());
  };

  const handleSubjectOrMessageChange = (e: string, type: string) => {
    setFormData({ ...formData, [type]: e });
  };

  const onSubmit = (data: IBroadcaseMessage) => {
    setModalOpen(true);
    handleUploadAlertClose();
    const formData = { ...data };
    formData["from"] = envVars.REACT_APP_MAIL_ID_FOR_SITE_ACCESS;
    formData["subject"] = formData["subject"].trim();
    formData["message"] = formData["message"].trim();
    setFormData({ ...formData });
  };

  const validationSchema = Yup.object().shape({
    to: Yup.array().min(1, "To is required"),
    subject: Yup.string()
      .trim()
      .min(1, "Minimum 1 character is required")
      .max(200, "Maximum 200 characters allowed")
      .required("Subject is required"),
    message: Yup.string()
      .trim()
      .min(1, "Minimum 1 character is required")
      .max(2000, "Maximum 2000 characters allowed")
      .required("Message is required"),
  });

  const onMessageChange = (value: any) => {
    handleSubjectOrMessageChange(value, "message");
  };

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row justify="start" className="align-items-center">
          <ClayLayout.Col className="d-flex align-items-center">
            <PageTitle title={pageTitle} />
          </ClayLayout.Col>
        </ClayLayout.Row>
        <ClayLayout.Row>
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="p-4">
              <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={(e) => onSubmit(e)}
                validationSchema={validationSchema}
                innerRef={form}
              >
                {(formik) => (
                  <ClayForm onSubmit={formik.handleSubmit} className="m-4 mw-100">
                    <ClayLayout.Row justify="start">
                      <ClayLayout.Col xs={10} md={10} lg={10} className="mt3 pb-4">
                        <span>
                          Compose and send announcement to an individual or group of users.
                          <br />
                          Message is only sent to active users who have signed in atleast once and
                          are subscribed to announcements in their profile.
                        </span>
                      </ClayLayout.Col>
                    </ClayLayout.Row>
                    {sendAlerts && sendAlerts.length ? (
                      <div className="row">
                        {sendAlerts.map((report: IAlertMessage, key: number) => {
                          return (
                            <div
                              key={key}
                              className={`${
                                sendAlerts.length > 1 ? "col-md-6" : "col-md-12"
                              } col-sm-12`}
                            >
                              {report.type && report.message && (
                                <div className="c-mt-2" key={report.message}>
                                  <Alert handleClose={handleUploadAlertClose} report={report} />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}

                    <ClayLayout.Row justify="start">
                      <ClayLayout.Col xs={12} md={10} lg={8} className="mt3">
                        <FormControl
                          control="text"
                          label="From"
                          placeholder={envVars.REACT_APP_MAIL_ID_FOR_SITE_ACCESS}
                          name="from"
                          autoComplete={"off"}
                          readOnly
                        />
                      </ClayLayout.Col>
                    </ClayLayout.Row>
                    <ClayLayout.Row justify="start">
                      <ClayLayout.Col xs={12} md={10} lg={8} className="mt3">
                        <FormControl
                          control="dropdownWithCheckbox"
                          id="to"
                          name="to"
                          label={"To"}
                          required={true}
                          items={emailUserList}
                          selected={selectedEmail}
                          filteredList={emailUserList}
                          onFilterCheckboxChange={onFilterCheckboxChange}
                          enterpriseFilterList={enterpriseFilterList}
                          selectAll={selectAll}
                          searchString={searchString}
                          setSearchString={setSearchString}
                          handleSelectAllEnterpriceFilter={handleSelectAllEnterpriceFilter}
                          isAllEnterpriseFilterSelected={selectAllEnterpriseFilter}
                          onChange={(checked: boolean, item: IBroadcaseMessageTo) =>
                            handleMultiSelectChange(checked, item)
                          }
                          onCloseLabel={(item: IBroadcaseMessageTo) =>
                            handleMultiSelectChange(false, item)
                          }
                          searchable={true}
                          parentPagination={paginationInfo}
                          handleParentPagination={handlePageChange}
                          handleSelectAll={handleSelectAll}
                          readOnly
                        />
                      </ClayLayout.Col>
                    </ClayLayout.Row>

                    <ClayLayout.Row justify="start">
                      <ClayLayout.Col xs={12} md={10} lg={8} className="mt3">
                        <FormControl
                          control="text"
                          label="Subject"
                          name="subject"
                          required={true}
                          onBlur={(e: any) =>
                            handleSubjectOrMessageChange(e.target.value, "subject")
                          }
                        />
                      </ClayLayout.Col>
                    </ClayLayout.Row>
                    <ClayLayout.Row justify="start">
                      <ClayLayout.Col xs={12} md={10} lg={8} className="mt3">
                        <label htmlFor="message">
                          <span className="text-red">* </span>
                          {"Message"}
                        </label>
                        <ReactQuill
                          theme="snow"
                          value={formData.message}
                          onChange={(e) => onMessageChange(e)}
                          modules={RICH_TEXT_MODULES}
                        />
                      </ClayLayout.Col>
                    </ClayLayout.Row>
                    <ClayLayout.Row justify="start">
                      <ClayLayout.Col xs={10} md={10} lg={10} className="mt3">
                        <span>
                          Information on how to unsubscribe and email signature will be added
                          automatically.
                        </span>
                      </ClayLayout.Col>
                    </ClayLayout.Row>
                    <ClayLayout.Row className="mt-3">
                      <ClayButton
                        type="submit"
                        className="ml-2"
                        displayType={"primary"}
                        style={{ fontSize: "small" }}
                        disabled={
                          !formData["message"] ||
                          !formData["subject"] ||
                          !formData["message"].length ||
                          formData["message"] === "<p><br></p>"
                        }
                      >
                        <span className="inline-item inline-item-before">
                          <ClayIcon spritemap={Sprite} symbol="angle-right" />
                        </span>
                        Send
                      </ClayButton>
                    </ClayLayout.Row>
                  </ClayForm>
                )}
              </Formik>
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
      {isModalOpen && (
        <ConfirmSendModal
          confirmSend={handleConfirmSend}
          modalClose={() => setModalOpen(false)}
          body={`Are you sure want to send the broadcast mail ?`}
        />
      )}
    </React.Fragment>
  );
}

export default Manage;
