import ClayButton from "@clayui/button";
import DropDown from "@clayui/drop-down/lib/DropDown";
import ClayDropDown from "@clayui/drop-down/lib/DropDown";
import { ClaySelectWithOption } from "@clayui/form";
import { Table } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import TableCellRenderer from "@components/cellRenderer/TableCellRenderer";
import { ROUTE_PATHS } from "@constants";
import { IAlertMessage, ISignedinUser } from "@interfaces";
import {
  getDashboardActiveSerialNumber,
  getDashboardData,
  getDashboardDataResponse,
  getUserSiteIds,
  loadActiveSerialNumberData,
  loadDashboardData,
  loadUserSiteIds,
  setDashboardDataResponse,
} from "@store/overview/slice";
import { getSignedInUser } from "@store/userDetails/slice";
import { GET_MONTH_YEAR_FROM_DATE, isAuditRepoAdmin, isGTOMSDSupport, isMCLUser } from "@utils";
import FileSaver from "file-saver";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from "react-redux";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useCurrentPng } from "recharts-to-png";
import { ISelectedOption } from "utils/interfaces/overview";

import Alert from "./../../../components/Alert";
import "./Overview.scss";
import OverviewModal from "./OverviewModal";
import {
  ActiveSerialNumber,
  DEFAULT_LABEL,
  YAXISKEY,
  dashboardBody,
  downloadMergedData,
  formatTick,
  getFileName,
  getMaxBaseline,
  getRoundedMaxValue,
  getSum,
  lowerHeader,
  serialNumBody,
  sorter,
} from "./constants";

//TODO add in slice
let rowsData: any = [{ type: "Total Usage" }, { type: "Production Usage" }];
let overviewStartingDataObj: any = {};
let columns: any = [];
let activeSerialNumberColumns: any = [];
let activeSerialNumberRowData: any = {};
let activeLpars: any = {};
let baseline: any = 0;

export function Overview() {
  const dispatch = useDispatch();
  const userDetails: ISignedinUser = useSelector(getSignedInUser);
  const stateDashboardData = useSelector(getDashboardData);
  const stateActiveSerialNumberData = useSelector(getDashboardActiveSerialNumber);

  const [dashboardData, setDashboardData] = useState([]);
  const [selectedSiteId, setSelectedSiteId] = useState<ISelectedOption[]>([DEFAULT_LABEL]);
  const [overviewColumns, setOverviewColumns] = useState<any>([]);
  const [overviewRows, setOverviewRows] = useState<any>([]);
  //TODO: Add in slice
  const [activeSerialNumberData, setActiveSerialNumberData] = useState<any>([]);
  const [activeSerialNumberCols, setActiveSerialNumberCols] = useState<any>([]);
  const [activeLparData, setActiveLparData] = useState<any>({});

  const typeaheadRef: any = useRef();
  const userSiteIds = useSelector(getUserSiteIds);
  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";
  const [modalData, setModalData] = useState({ isOpen: false, data: "" });
  const [reportingOptions, setReportingOptions] = useState<any>([]);
  const [selectedReportingPeriod, setSelectingReportingPeriod] = useState("");
  const [responseAlerts, setResponseAlerts] = useState([] as IAlertMessage[]);
  const dashboardDataResponse = useSelector(getDashboardDataResponse);
  const [active, setActive] = useState(false);
  const [getPng, { ref }] = useCurrentPng();

  const isAuditRepoAdminUser = isAuditRepoAdmin(userDetails ? userDetails.role : "");
  const gtoMsdSupport = isGTOMSDSupport(userDetails ? userDetails.role : "");

  const setData = (obj: any, index: number) => {
    if (index === 0) {
      columns = [{ headerName: `Month`, field: `month` }];
      rowsData[0]["month"] = <b>{"Total Usage"}</b>;
      rowsData[1]["month"] = <b>{"Production Usage"}</b>;
      if (activeSerialNumberColumns.length) activeSerialNumberColumns = [];
    }

    const headerName = GET_MONTH_YEAR_FROM_DATE(obj[1]);
    const field = lowerHeader(headerName);
    columns.push({
      headerName: `${headerName}`,
      field,
    });
    activeSerialNumberColumns.push({
      headerName: `${headerName}`,
      field,
      cellRenderer: <ActiveSerialNumber />,
    });
    //To add data for table total Usage (Actual consumption), production usage
    rowsData[0][field] = obj[3] || "-";
    rowsData[1][field] = obj[4] || "-";

    overviewStartingDataObj[field] = {};
    overviewStartingDataObj[field].headerName = headerName;
    overviewStartingDataObj[field].monthYear = obj[1];
    overviewStartingDataObj[field].actualConsumption = Number(parseFloat(obj[3]).toFixed(2)) || 0;
    overviewStartingDataObj[field].actualConsumptionStringValue = obj[3] || "-";
    overviewStartingDataObj[field].baseline = Number(parseFloat(obj[2]).toFixed(2)) || 0;
    overviewStartingDataObj[field].baselineStringValue = obj[2] || "-";
    overviewStartingDataObj[field].productionUsage = obj[4] || "-";
    overviewStartingDataObj[field].msuPercentage = obj[5] || "-";
    overviewStartingDataObj[field].lpars = obj[6] || "-";
    overviewStartingDataObj[field].reportingField = obj[7] || "";
    overviewStartingDataObj[field].reportingValue = obj[8] || "";
    const lparData = (obj[6] && obj[6] && obj[6].split(",")) || [];
    activeLpars = { ...activeLpars, [field]: lparData };
    baseline = getMaxBaseline(baseline, overviewStartingDataObj[field].baseline);
  };

  const resetData = () => {
    setOverviewColumns([]);
    setDashboardData([]);
    setOverviewRows([]);
    setActiveSerialNumberCols([]);
    setActiveSerialNumberData([]);
    activeSerialNumberColumns = [];
    // activeSerialNumberRowData = {};
    columns = [];
    columns.push({
      headerName: "",
      field: "type",
      cellRenderer: (
        <TableCellRenderer
          type="labelIcon"
          fieldName="type"
          labelIconTitle="Total Usage is the consumption of production and non-production (application development/test) LPARs. 
          (NOTE: the total from N7 section)"
        />
      ),
    });
    rowsData = [{ type: "Total Usage" }, { type: "Production Usage" }];
    overviewStartingDataObj = {};
  };

  const handleReportingPeriod = (val: any) => {
    resetData();
    const data = stateDashboardData.filter((obj: any) => obj[8] === Number(val));
    data.sort(sorter);
    baseline = 0;
    const lastIndex = data.length - 1 || 0;
    data.forEach((obj: any, idx: number) => {
      setData(obj, idx);
    });
    //To add last Total column and values
    if (lastIndex) {
      columns.push({
        headerName: "Total",
        field: "total",
      });
      rowsData[0]["total"] = <b>{getSum(data, 3)}</b>;
      rowsData[1]["total"] = <b>{getSum(data, 4)}</b>;
    }
    setActiveLparData({ ...activeLpars });

    setOverviewColumns(columns);
    setDashboardData(Object.values(overviewStartingDataObj));
    setOverviewRows(rowsData);
    setSelectingReportingPeriod(val);

    activeSerialNumberColumns.forEach((obj: any, index: number) => {
      activeSerialNumberRowData[obj.field] = activeSerialNumberRowData[obj.field] || ["-"];
    });
    if (stateActiveSerialNumberData.length > 0) {
      setActiveSerialNumberCols(activeSerialNumberColumns);
      setActiveSerialNumberData([activeSerialNumberRowData]);
    }
  };

  const onModalClose = () => {
    document.body.style.overflow = "visible";
    setModalData({ isOpen: false, data: "" });
  };

  //errror handling for overview.
  useEffect(() => {
    if (dashboardDataResponse && dashboardDataResponse.hasOwnProperty("success")) {
      setResponseAlerts([
        {
          type: "error",
          message:
            dashboardDataResponse.errors?.[0]?.details?.[0] ||
            dashboardDataResponse.errors?.[0]?.errorMessage ||
            "Failed to upload the report. Contact your security administrator.",
        },
      ]);
    }
  }, [dashboardDataResponse]);

  //Check if user is MCL and redirect to repository page
  useEffect(() => {
    if (isOktaEnabled && isValidUser !== "true") {
      window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
    } else if (isMCLUser()) {
      dispatch(loadUserSiteIds());
    } else {
      window.location.href = ROUTE_PATHS.REPOSITORY;
    }
    return () => {
      resetData();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //To set the default first site Id
  useEffect(() => {
    if (userSiteIds.length) {
      setSelectedSiteId([userSiteIds[0]] || []);
    }
  }, [userSiteIds]);

  //To Make API calls for selected support site Id
  useEffect(() => {
    if (selectedSiteId && selectedSiteId[0] && selectedSiteId[0].id) {
      resetData();
      setReportingOptions([]);
      activeSerialNumberRowData = {};
      dispatch(loadDashboardData(dashboardBody(selectedSiteId)));
      dispatch(loadActiveSerialNumberData(serialNumBody(selectedSiteId)));
    }
  }, [selectedSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

  //To Format Consumption Table and Chart data
  useEffect(() => {
    if (stateDashboardData) {
      const reporting_options: any = {};
      const initial_data: any = [];
      stateDashboardData.forEach((obj: any, index: number) => {
        reporting_options[obj[7]] = { label: obj[7], value: obj[8] };
        //TODO: Need to fix properly
        if (obj[8] === 0 || obj[8] === 0.0) {
          initial_data.push(obj);
        }
      });

      const reportingOptions_values = Object.values(reporting_options);
      reportingOptions_values.sort((a: any, b: any) => b.value - a.value);
      if (reportingOptions_values.length > 0) {
        setReportingOptions(reportingOptions_values);
        setSelectingReportingPeriod("0");
      }
      if (initial_data.length > 0) {
        initial_data.sort(sorter);
        baseline = 0; //TODO
        const lastIndex = initial_data.length - 1 || 0;
        initial_data.forEach((obj: any, idx: number) => {
          setData(obj, idx);
        });
        //To add Total column and values
        if (lastIndex) {
          columns.push({
            headerName: "Total",
            field: "total",
          });
          rowsData[0]["total"] = <b>{getSum(initial_data, 3)}</b>;
          rowsData[1]["total"] = <b>{getSum(initial_data, 4)}</b>;
        }
        setActiveLparData({ ...activeLpars });

        setOverviewColumns(columns);
        setDashboardData(Object.values(overviewStartingDataObj));
        setOverviewRows(rowsData);
      }
    }
  }, [stateDashboardData]);

  //To format Active Serial number data
  useEffect(() => {
    if (stateActiveSerialNumberData) {
      stateActiveSerialNumberData.forEach((obj: any, index: number) => {
        const headerName = GET_MONTH_YEAR_FROM_DATE(obj[1]);
        const field = lowerHeader(headerName);
        if (!activeSerialNumberRowData[field]) {
          activeSerialNumberRowData[field] = [];
        }
        activeSerialNumberRowData[field].push(obj[2] || "-");
      });
      if (stateActiveSerialNumberData.length > 0) {
        setActiveSerialNumberCols(activeSerialNumberColumns);
        setActiveSerialNumberData([activeSerialNumberRowData]);
      }
    }
  }, [stateActiveSerialNumberData]);

  let maxValue = 0;
  //manually setting the yaxis data
  if (overviewRows && overviewRows.length) {
    const data: any = Object.values(overviewStartingDataObj).map((obj: any) => obj[YAXISKEY]);
    let value = Math.round(Math.max(...data));
    value = getRoundedMaxValue(Math.round(value * 1.1)); //adding 10% to the max values
    let newBaseline = getRoundedMaxValue(Math.round(Math.ceil(baseline) * 1.1));
    maxValue = Math.max(value, newBaseline); //consider max of baseline and actual consumption
  }

  const handleAlertClose = () => {
    dispatch(setDashboardDataResponse({}));
    setResponseAlerts([]);
  };

  const handleCSVDownload = () => {
    downloadMergedData(
      overviewStartingDataObj,
      activeSerialNumberData,
      getFileName(selectedSiteId[0].siteId, "Overview"),
    );
  };

  const handleChartDownload = useCallback(
    async (siteId) => {
      const png = await getPng();

      // Verify that png is not undefined
      if (png) {
        const filename = getFileName(siteId, "Chart");
        // Download with FileSaver
        FileSaver.saveAs(png, filename);
      }
    },
    [getPng],
  );

  return (
    // <div className="">
    //   <Alert
    //     report={{
    //       type: "warning",
    //       message: `<div>
    //       This page would be unavailable during the below time window due to the planned outage of ERP Eco System: From (Saturday, May 13 at 12pm Singapore time) To (Monday, May 15 at 12pm Singapore time)
    //     </div>`,
    //     }}
    //   />{" "}
    // </div>
    <React.Fragment>
      <div className="row">
        <div className="col-lg-4 mt-3">
          <label className="overview-label"> Overview: </label>
          <div>
            <Typeahead
              id={"overview"}
              labelKey="label"
              align="justify"
              options={userSiteIds}
              placeholder={"Search Site name"}
              selected={selectedSiteId}
              ref={typeaheadRef}
              onChange={(e: any) => {
                handleAlertClose();
                setSelectedSiteId(e);
              }}
            />
          </div>
        </div>
        {reportingOptions?.length > 0 && (
          <div className="col-lg-4 mt-3">
            <label className="overview-label"> Reporting Period: </label>
            <ClaySelectWithOption
              aria-label={"View Reporting Period"}
              id={"feedback"}
              value={selectedReportingPeriod}
              options={reportingOptions}
              onChange={(e: any) => {
                handleReportingPeriod(e.target.value);
              }}
            />
          </div>
        )}
        {!isAuditRepoAdminUser &&
        !gtoMsdSupport &&
        responseAlerts.length === 0 &&
        overviewRows.length ? (
          <div className="col-lg-4 mt-3 d-flex align-items-end justify-content-end">
            <ClayDropDown
              trigger={<ClayButton>Export</ClayButton>}
              active={active}
              onActiveChange={setActive}
            >
              <DropDown.ItemList>
                <DropDown.Item onClick={() => handleCSVDownload()}>
                  Overview Data (.csv)
                </DropDown.Item>
                <DropDown.Item onClick={() => handleChartDownload(selectedSiteId[0].siteId)}>
                  Download as PNG
                </DropDown.Item>
              </DropDown.ItemList>
            </ClayDropDown>
          </div>
        ) : null}
      </div>
      {responseAlerts && responseAlerts.length ? (
        <div className="row">
          {responseAlerts.map((report: IAlertMessage, key: number) => {
            return (
              <div
                key={key}
                className={`${responseAlerts.length > 1 ? "col-md-6" : "col-md-12"} col-sm-12`}
              >
                {report.type && report.message && (
                  <div className="c-mt-2" key={report.message}>
                    <Alert handleClose={handleAlertClose} report={report} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : null}

      <div className="my-3">
        <Table
          rowClassName="overview-table"
          definitions={overviewColumns}
          dataSource={overviewRows}
          borderedColumns={false}
          borderless={true}
          headVerticalAlignment={"middle"}
          headingNoWrap={true}
          hover={false}
          noWrap={true}
          responsive={true}
          responsiveSize={"sm"}
          striped={true}
          hideFilter={true}
          tableVerticalAlignment={"middle"}
        />
      </div>
      {dashboardData && dashboardData.length > 0 && (
        <div className="mb-4">
          <div className="overview-container">
            <ResponsiveContainer width="99%" aspect={2}>
              <ComposedChart
                width={500}
                height={500}
                data={dashboardData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                ref={ref}
                // onClick={(e: any) => {
                //   setModalData({ isOpen: true, data: e.activePayload?.[0]?.payload || "" });
                // }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  tickLine={false}
                  xAxisId={0}
                  dy={0}
                  dx={-0}
                  label={{ value: "", angle: 0, position: "top" }}
                  angle={0}
                  interval={0}
                  axisLine={false}
                  allowDataOverflow={true}
                  dataKey="headerName"
                  tick={{ fontSize: 14 }}
                />
                {/* <XAxis
                  xAxisId={1}
                  dy={1}
                  dx={0}
                  label={{ value: "", angle: 0, position: "bottom" }}
                  interval={0}
                  minTickGap={-200}
                  dataKey="msuPercentage"
                  tickLine={false}
                  tick={{ fontSize: 14 }}
                /> */}
                <YAxis
                  type="number"
                  yAxisId={0}
                  dataKey={YAXISKEY}
                  tickCount={18}
                  label={{
                    value: "MSU/Hour",
                    angle: -90,
                    position: "left",
                    offset: 14,
                  }}
                  domain={[0, maxValue]}
                  tickFormatter={formatTick}
                />
                <YAxis
                  orientation="right"
                  yAxisId={1}
                  dataKey={YAXISKEY}
                  type="number"
                  tickCount={18}
                  label={{
                    value: "MSU/Hour",
                    angle: 90,
                    position: "right",
                    offset: 20,
                  }}
                  domain={[0, maxValue]}
                  tickFormatter={formatTick}
                />
                <Legend />
                {/* <Tooltip content={<CustomTooltip />} /> */}
                <Bar dataKey="actualConsumption" fill="#c3c3c3" name="Total Usage" />
                <Bar dataKey="productionUsage" fill="#015c8a" name="Production Usage" />
                <Line
                  name="Baseline"
                  type="monotone"
                  dataKey="baseline"
                  stroke="#33b5e5"
                  strokeWidth={2}
                  legendType={"plainline"}
                  dot={false}
                  connectNulls
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
      <div className="my-3">
        <div>
          <label className="overview-label">Active Serial Numbers</label>
        </div>
        <div className="table-container">
          <Table
            rowClassName="overview-table"
            definitions={activeSerialNumberCols}
            dataSource={activeSerialNumberData}
            borderedColumns={false}
            borderless={true}
            headVerticalAlignment={"middle"}
            headingNoWrap={true}
            hover={false}
            noWrap={true}
            responsive={true}
            responsiveSize={"sm"}
            striped={true}
            hideFilter={true}
            tableVerticalAlignment={"middle"}
          />
        </div>
      </div>
      <div className="my-4">
        <div>
          <label className="overview-label">Application Development/Test LPARs</label>
        </div>
        <div className="table-container">
          <Table
            rowClassName="overview-table"
            className="table-container"
            definitions={activeSerialNumberCols}
            dataSource={[activeLparData]}
            borderedColumns={false}
            borderless={true}
            headVerticalAlignment={"middle"}
            headingNoWrap={true}
            hover={false}
            noWrap={true}
            responsive={true}
            responsiveSize={"sm"}
            striped={true}
            hideFilter={true}
            tableVerticalAlignment={"middle"}
          />
        </div>
      </div>
      {modalData.isOpen && (
        <OverviewModal
          modalData={modalData}
          setModalData={setModalData}
          onModalClose={onModalClose}
        />
      )}
    </React.Fragment>
  );
}
