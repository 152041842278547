import { GET_USER_EMAIL_AUTHHUB_LOWERCASE, isAdmin, isMCLUser, isSuperAdmin, isUser, isConsoleAdmin, isAuditRepoAdmin, isGTOMSDSupport } from "../utils";

export const PREVIEW_TABLE_HEADERS = ["n7_lpar_msus", "n5_lpar_highest"];

export const ALERT_CLOSING_TIME_IN_MS = 2500;

export const MAPPER_PREVIEW_TAB: any = {
  uploadDate: "Uploaded",
  uploadedBy: "Uploaded By",
  submittedBy: "Submitted By",
  customerName: "Customer Name",
  customerNumber: "Customer Number",
  reporterEmail: "Submitter Email",
  reportingPeriodStartDate: "Period Start",
  reportingPeriodEndDate: "Period End",
  serialNumber: "Serial Number",
  machineTypeAndModel: "Machine Type And Model",
  machineRatedCapacity: "Machine Rated Capacity",
  cpc: "CPC (N5)",
  n7_lpar_msus: "LPARs",
};

export const PREVIEW_TABLE_DATE_COLUMNS = ["uploadDate"];

export const REPOSITORY_COLUMNS = [
  { headerName: "Action", field: "action", sortable: false },
  {
    headerName: "Uploaded",
    field: "uploadedOn",
    sortable: true,
    filterable: true,
    filterType: "date",
  },
  { headerName: "Uploaded By", field: "uploadedBy", sortable: true, filterable: true },
  { headerName: "Submitted By", field: "submittedBy", sortable: true, filterable: true },
  { headerName: "Customer Name", field: "customerName", sortable: true, filterable: true },
  { headerName: "Customer Email", field: "customerEmail", sortable: true, filterable: true },
  { headerName: "Machine Serial Nos.", field: "machineNos", sortable: true, filterable: true },
  {
    headerName: "Period Start",
    field: "periodStart",
    sortable: true,
    type: "datePicker",
    filterable: true,
    filterType: "date",
  },
  {
    headerName: "Period End",
    field: "periodEnd",
    sortable: true,
    type: "datePicker",
    filterable: true,
    filterType: "date",
  },
];

export const navBarOptions = (role: string) => {
  const isGTOMSDRole = isGTOMSDSupport(role);
  const isAuditRepoAdminRole = isAuditRepoAdmin(role) || isGTOMSDRole;
  const isSuperAdminRole = isSuperAdmin(role) || isAuditRepoAdminRole;
  const isAdminUser = isAdmin(role) || isSuperAdminRole;
  const showAll = isUser(role) || isAdminUser;
  const isMclUser = isMCLUser();
  
  return [
    { name: "Getting Started", to: "/getting-started", active: false, isVisible: showAll },
    {
      name: "Overview",
      to: "/overview",
      active: false,
      isVisible: isMclUser,
    },
    {
      name: "Repository",
      to: "/repository",
      active: false,
      isVisible: showAll,
    },
    { name: "Sites", to: "/sites", active: false, isVisible: isAdminUser },
    { name: "SCRT User Administration", to: "", active: false, isVisible: (!isAuditRepoAdminRole && isAdminUser) },
    {
      name: "Additional Mainframe Reporting",
      to: "/additional-mainframe-reporting",
      active: false,
      isVisible: showAll,
    },
    { name: "Audit Repository", to: "/audit", active: false, isVisible: (!isGTOMSDRole && isSuperAdminRole) },
    { name: "Need Help?", to: "/help", active: false, isVisible: showAll },
    { name: "Broadcom SCRT Admin Console", to: "/console", active: false, isVisible: isConsoleAdmin() },
  ];
};

export const ACTIVE_FILTER_OPTIONS = [
  { label: "All", value: "" },
  { label: "Yes", value: "active" },
  { label: "No", value: "inactive" },
];

export const STATUS_FILTER_OPTIONS = [
  { label: "All", value: "" },
  { label: "Success", value: "success" },
  { label: "Warning", value: "warning" },
  { label: "Error", value: "error" },
];

export const REPO_LOGS_FILTER_OPTIONS = [
  { label: "All", value: "" },
  { label: "Pending", value: "pending" },
  { label: "Failed", value: "failed" },
  { label: "Error", value: "error" },
];

export const DEFAULT_PAGINATION = {
  activeDelta: 25,
  activePage: 0,
  ellipsisBuffer: 0,
  totalItems: 0,
};

export const UTC_TZ_FORMAT = "YYYY-MM-DDTHH:mm:ss:sssZ";
export const USER_TZ_FORMAT = "YYYY-MM-DD hh:mm A";
export const USER_FORMAT = "YYYY-MM-DD";

//For Pagination options
export const DELTAS = [{ label: 25 }, { label: 50 }, { label: 100 }, { label: 300 }];
export const DEFAULT_DELTA = 25;
export const MAX_DELTA = 300;

//For User roles
export const ROLE_USER = "user";
export const ROLE_ADMIN = "admin";
export const ROLE_SUPER_ADMIN = "super_admin";
export const ROLE_AUDIT_REPO_ADMIN = "audit_repo_admin";
export const ROLE_GTO_MSD_SUPPORT = "gto_msd_support";
export const SUPER_ADMIN_SITEID = "-1";
export const AUDIT_REPO_ADMIN_SITEID = "-1";
export const GTO_MSD_SUPPORT_SITEID = "-1";
export const SUPER_ADMIN_ROLE = "Broadcom SCRT Admin";
export const AUDIT_REPO_ROLE = "Broadcom Audit/Compliance";
export const GTO_MSD_SUPPORT_ROLE = "GTO/MSD Support";

export const DOWNLOAD_REDIRECT_URL = (url: string, orderId: number, fileName: string) => {
  const userid = GET_USER_EMAIL_AUTHHUB_LOWERCASE();
  let redirectURL = `${url}?userid=${userid}&oid=${orderId}`;
  if (fileName) {
    redirectURL = `${redirectURL}&file=${fileName}`;
  }
  window.open(redirectURL, "_blank", "noopener,noreferrer");
};

export const BASEPATH = process.env.NODE_ENV === "development" ? process.env.REACT_APP_BASE_URL : "/scrtservices";

export const DOWNLOAD_FILE = (blob: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob(blob));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();
};

// CRUSH FTP DOWNLOAD CONFIG
export const DONWLOAD_SOURCE = {
  Repository: "R",
  AdditionMfReporting: "AMR",
};

export const RICH_TEXT_MODULES = {
  toolbar: [
    [
      "bold",
      "italic",
      "link",
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
};

export const RICH_TEXT_INDENT: any = {
  "ql-indent-1": "3em",
  "ql-indent-2": "6em",
  "ql-indent-3": "9em",
  "ql-indent-4": "12em",
  "ql-indent-5": "15em",
  "ql-indent-6": "18em",
  "ql-indent-7": "21em",
  "ql-indent-8": "24em",
  "ql-indent-9": "27em",
  "ql-indent-10": "30em",
};

export const OKTATOKENSTORAGEKEY = "okta-token-storage";
export const SCRTLOCALKEY = "scrt-local";
export const checkIfInternalUser = () => {
  const store = JSON.parse(localStorage.getItem("scrt-local") || "{}");
  return !!store.scrtInternal || false;
};
export const checkIfSCRTAccess = () => {
  const store = JSON.parse(localStorage.getItem("scrt-local") || "{}");
  return !!store.role || !!store.hasScrtAccess || false;
};

export const LOCAL_ENV = "local";
export const DEV_ENV = "dev";
export const QAT_ENV = "qat";
export const VERIFY_ENV = "stage";
export const PROD_ENV = "prod";

export const AUDIT_IBM_OPTIONS = [
  { label: "All", value: null },
  { label: "Yes", value: true },
  { label: "No", value: false },
];
