
export const CONSOLE_TABS = {
    userManagement: "SCRT User Management",
    msnManagemet: "MSN Management",
    scrtReportManagement: "SCRT Report Management",
    reminderManagement: "Reminder Management",
    customerNumberMapping: "Customer Mapping",
    broadcastMessage:"Broadcast Message",
    productCatalogManagement:"Product Catalog Management",
    emailResponse:"Email/Broadcast Sent"
  };
  
  export const CONSOLE_TABS_LIST = [
    {
      display: "SCRT User Management",
      show: true,
      navPath: CONSOLE_TABS.userManagement,
    },
    {
      display: "MSN Management",
      show: true,
      navPath: CONSOLE_TABS.msnManagemet,
    },
    {
      display: "SCRT Report Management",
      show: true,
      navPath: CONSOLE_TABS.scrtReportManagement,
    },
    {
        display: "Reminder Management",
        show: true,
        navPath: CONSOLE_TABS.reminderManagement,
    },
    {
        display: "Customer Mapping",
        show: true,
        navPath: CONSOLE_TABS.customerNumberMapping,
    },
    {
        display: "Broadcast Message",
        show: true,
        navPath: CONSOLE_TABS.broadcastMessage,
    },
    {
        display: "Product Catalog Management",  
        show: true,
        navPath: CONSOLE_TABS.productCatalogManagement,
    },
    {
      display: "Email/Broadcast Sent",
      show: true,
      navPath: CONSOLE_TABS.emailResponse,
    },
  ];
  
