import { useState } from "react";
import { NavTabs } from "@common-components";
import ClayLayout from "@clayui/layout";
import { CONSOLE_TABS, CONSOLE_TABS_LIST } from "@store/console/constant";
import SCRTReportManagement from "./scrtReportManagement/SCRTReportManagement";
import ReminderManagement from "./reminderManagement/RemiderManagement";
import ProductCatalogManagement from "./productCatalogManagement/ProductCatalogManagement";
import MSNManagemet from "./MSNMangement/MSNManagemet";
import CustomerNumberMapping from "./customerNumberMapping/CustomerNumberMapping";
import UserManagement from "./broadcomSRTUserManagement/UserManagement";
import BroadcastMessage from "./broadcastMessage/BroadcastMessage";
import EmailResponse from "./emailResponse/EmailResponse";
import { ROUTE_PATHS } from "@constants";


const Console =()=>{
    
    const navTabs=CONSOLE_TABS_LIST
    const [currentTab, setCurrentTab] = useState(CONSOLE_TABS.userManagement);

    const getTabComponent = () => {
        switch (currentTab) {
          case CONSOLE_TABS.userManagement:
            return <UserManagement />;
          case CONSOLE_TABS.msnManagemet:
            return <MSNManagemet />;
          case CONSOLE_TABS.scrtReportManagement:
            return <SCRTReportManagement />;
          case CONSOLE_TABS.reminderManagement:
            return <ReminderManagement />;
          case CONSOLE_TABS.customerNumberMapping:
            return <CustomerNumberMapping />;
          case CONSOLE_TABS.broadcastMessage:
            return <BroadcastMessage />;
          case CONSOLE_TABS.productCatalogManagement:
            return <ProductCatalogManagement />;
          case CONSOLE_TABS.emailResponse:
            return <EmailResponse />;  
        }
    };

    const handleTabChanged = (navPath: any) => {
    setCurrentTab(navPath);
    };

    return (
        <>
            <ClayLayout.ContainerFluid view size={false}>
                <ClayLayout.Row className={"mt-4"}>
                    <ClayLayout.Col>
                    <NavTabs
                        tabList={navTabs}
                        disableNav="true"
                        defaultTab={currentTab}
                        tabChanged={handleTabChanged}
                        consoleNavBar={true}
                    />
                    </ClayLayout.Col>
                </ClayLayout.Row>
                {getTabComponent()}
            </ClayLayout.ContainerFluid>
        </>
    )
}

export default Console