import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { PageTitle, Table } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { DEFAULT_DELTA, ROLE_USER, ROUTE_PATHS } from "@constants";
import { IReminderLogs, ISignedinUser } from "@interfaces";
import {
  getReminderLogs,
  getReminderLogsData,
  getReminderLogsDataApi,
  getReminderLogsFilterState,
  getReminderLogsPageState,
  getReminderLogsSortState,
  getNotification,
  handleReminderLogsPaginationState,
  parseLogData,
  reminderLogs,
  resetReminderLogsFilters,
  resetRepositoryLogsState,
  resetReminderLogsData,
  changeReminderSetting,
  handleIsConsoleReminder,
  getIsReminderFlag,
  handleIsReminderFlag
} from "@store/repositoryLogs/slice";
import { GET_USER_ROLE,isAuditRepoAdmin,isConsoleAdmin, isGTOMSDSupport } from "@utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {reminderLogConstants} from "./ReminderLogsConstants"; 
import "./repositoryLogsTables.scss";
import { useLocation } from "react-router-dom";
import { ClayInput,ClayToggle } from "@clayui/form";
import ClayButton from "@clayui/button";
import { dateFormat, exportCSVFile, timeStampFormat } from "./constants";
import { getSignedInUser } from "@store/userDetails/slice";

function ReminderLogs(props: any) {
  const location = useLocation();
  const isConsoleReminderLogs = location.pathname === ROUTE_PATHS.CONSOLE;

  const dispatch = useDispatch();
  const [isSearched,setIsSearched]=useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>("");
  const [isSearchGoingOn, setIsSearchGoingOn] = useState<boolean>(false);
  const sort = useSelector(getReminderLogsSortState);
  const filterValue = useSelector(getReminderLogsFilterState);
  const paginationInfo = useSelector(getReminderLogsPageState);
  const reminderLogList= useSelector(getReminderLogs);
  const isNotificationEnabled = useSelector(getNotification);
  const isReminderFlag = useSelector(getIsReminderFlag)
  const [data,setData] = useState([])  
  const signedInUser: ISignedinUser = useSelector(getSignedInUser);
  const auditRepoAdmin = isAuditRepoAdmin(signedInUser?.role)
  const gtoMsdSupport = isGTOMSDSupport(signedInUser?.role)
  const pageTitle="Reminder Management"
  
  useEffect(()=>{
    dispatch(handleIsConsoleReminder(isConsoleReminderLogs))
  },[isConsoleReminderLogs])

  useEffect(()=>{
    isConsoleReminderLogs ? searchValue.length ? setData(reminderLogList) : setData([]): setData(reminderLogList)
  },[reminderLogList])
  

  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";

  const rowData = useSelector(getReminderLogsData);

  //Check if Valid user
  
  useEffect(() => {
    if(isConsoleReminderLogs){
      const access =isConsoleAdmin()  
      if(access === false) {
        window.location.href = ROUTE_PATHS.NO_ACCESS;
      }
    }else {
      if (isOktaEnabled && isValidUser !== "true") {
        window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
      } else if (GET_USER_ROLE() === ROLE_USER) {
        window.location.href = ROUTE_PATHS.NO_ACCESS;
      }
    }
    return () => {
      dispatch(resetRepositoryLogsState());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResetFilter = () => {
    let isExistingFilter = false;

    // check if any filters are present
    if (Object.keys(filterValue).length === 0) {
      isExistingFilter = false;
    } else {
      for (let key in filterValue) {
        if (filterValue.hasOwnProperty(key)) {
          isExistingFilter = filterValue[key] !== "" ? true : false;
          break;
        }
      }
    }

    if (sort.column !== "" && sort.order !== "") {
      isExistingFilter = true;
    }
    // reset filters only if there are any filters
    if (isExistingFilter) {
      if(isConsoleReminderLogs)
        {
          let val= {"supportSiteId":searchValue}
          dispatch(resetReminderLogsFilters(val,isConsoleReminderLogs));
          dispatch(resetReminderLogsData())
        }else{
          dispatch(resetReminderLogsFilters());
        }
    }
  };

  useEffect(() => {
    let newData: IReminderLogs[] =
      (rowData &&
        rowData.content &&
        rowData.content.length &&
        rowData.content.map((row: IReminderLogs) => ({
          ...row,
        }))) ||
      [];
    newData = parseLogData(
      [...newData],
      "erpAccountName",
      "erpAccountNumber",
      "erpAccountName",
      "||",
    );
    newData = parseLogData([...newData], "username", "toEmailAddress", "username");
    dispatch(reminderLogs(newData));
    const newPageInfo = {
      activeDelta: rowData?.size || DEFAULT_DELTA,
      activePage: rowData?.number + 1 || 1,
      ellipsisBuffer: 5,
      totalItems: rowData?.totalElements || 0,
    };
    dispatch(handleReminderLogsPaginationState(newPageInfo));
  }, [rowData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleToggleChange=(toggleValue:boolean)=>{
    //dispatch(handleNotification(toggleValue))
    const data={
      supportSiteId : searchValue,
      reminderFlag : toggleValue ? "Y" : "N"
    }
    dispatch(changeReminderSetting(data))
  }

  const getReminderLogsDetails = (
    sortInfo: any = sort,
    filter: any = filterValue,
    pageInfo: any = paginationInfo,
  ) => {
    const sortState = sortInfo && sortInfo.column ? sortInfo : null;
    
    isConsoleReminderLogs ? 
      filter.supportSiteId && filter.supportSiteId!=="" &&
        dispatch(getReminderLogsDataApi(sortState, filter, pageInfo)) :
      dispatch(getReminderLogsDataApi(sortState, filter, pageInfo));
  };

  const handleFilter = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1, activeDelta: DEFAULT_DELTA };
    isConsoleReminderLogs ? 
      isSearched && 
        searchValue.length >0 ? getReminderLogsDetails(null, val, newPagination) : handleResetFilter()  
    : 
    getReminderLogsDetails(null, val, newPagination);
  };

  const handleSort = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1 };
    dispatch(getReminderLogsDataApi(val, null, newPagination));
  };

  const handlePagination = (page: any, delta: number) => {
    const newPagination = { ...paginationInfo, activePage: page, activeDelta: delta };
    isConsoleReminderLogs ? 
      isSearched && 
        searchValue.length >0 ? getReminderLogsDetails(null, filterValue, newPagination) : handleResetFilter() :
    getReminderLogsDetails(null, null, newPagination);
  };

  const handleOnChange = (val:string) => {
    setIsSearched(true)
    setSearchValue(val);
  };

  useEffect(()=>{
    dispatch(handleIsReminderFlag(null))
    let val= {"supportSiteId":searchValue}
    const delayDebounceFn = setTimeout(() => {
      if (isSearchGoingOn) {
        setIsSearchGoingOn(false);
        handleFilter(val)
      }
    }, 660);
    return () => {
        clearTimeout(delayDebounceFn);
    };
  },[searchValue])

  const downloadCSVReport=()=>{
    let newList:any[]=[...data]
    let updatedList:any[]=[]
    let obj:any={}
    newList.forEach((data:any)=>{
        obj={
          "username":data.username ? data.username.replaceAll(",","") : " ",
          "erpAccountName":data.erpAccountName ? data.erpAccountName.replaceAll(",","") : " ",
          "supportSiteId":data.supportSiteId ? data.supportSiteId.replaceAll(",",""):" ",
          "reminderInDays":data.erpAccountName? data.erpAccountName.replaceAll(",","") :" " ,
          "isReportReminder":data.isReportReminder? data.isReportReminder.replaceAll(",",""):" " ,
          "serialNumber":data.serialNumber? data.serialNumber.replaceAll(",",""):" " ,
          "lastRemainderDate":data.lastRemainderDate? dateFormat(data.lastRemainderDate).replaceAll(",",""):" " ,
          "reportingPeriodStartDateEpoch":data.reportingPeriodStartDateEpoch? dateFormat(data.reportingPeriodStartDateEpoch).replaceAll(",",""):" " ,
          "reportingPeriodEndDateEpoch":data.reportingPeriodEndDateEpoch ? dateFormat(data.reportingPeriodEndDateEpoch).replaceAll(",","") :" ",
        }
      updatedList.push(obj)
    })
    let columnList:any[]=[...reminderLogConstants(handleResetFilter, isConsoleReminderLogs)]
    let headerObj:any={}
    columnList.map((header:any)=>{
      if(header.field !== "action"){
        headerObj[header.field] = header.headerName
      }
    })

    let fileType:string= "Reminder Logs"
    let fileName:string = `SCRT_${fileType}_${Date.now()}`
    let finalList:any[]=[headerObj,...updatedList]
    exportCSVFile(finalList,fileName)
  }

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        {
          isConsoleReminderLogs &&
          <ClayLayout.Row justify="start" className="align-items-center">
            <ClayLayout.Col className="d-flex align-items-center">
                <PageTitle title={pageTitle} />
            </ClayLayout.Col>
          </ClayLayout.Row>
        }
        <ClayLayout.Row>
        <ClayLayout.Col className="d-flex align-items-center flex-row-reverse">
          {
          (!gtoMsdSupport && !isConsoleReminderLogs) &&
            <ClayButton 
              className="ml-3"
              displayType="primary" 
              type="button" 
              onClick={downloadCSVReport}
            >
              Export
            </ClayButton>
          }
          </ClayLayout.Col>
          <ClayCard className="m-3 w-100">
            <ClayCard.Body className="p-4">
              { isConsoleReminderLogs &&
                <ClayLayout.Row className="mb-4">
                  <ClayLayout.Col size={8}>
                    <div className="serial-container">
                      <div className="serial-label">Support Site ID: &emsp;</div>
                      <ClayInput
                        type="text"
                        id={"supportSiteId"}
                        placeholder={"Search With Support site Id"}
                        value={searchValue}
                        onChange={(event) => {
                          setIsSearchGoingOn(true);
                          handleOnChange(event.target.value)
                      }}
                      />
                    </div>
                  </ClayLayout.Col>
                  { (searchValue && (isReminderFlag==="Y" || isReminderFlag==="N")) &&
                      <ClayLayout.Col size={4}>
                        <div className="d-flex justify-content-end">
                          <ClayToggle
                            label={isNotificationEnabled ? "Reminder On" : "Reminder Off"}
                            toggled={isNotificationEnabled}
                            onToggle={(toggleValue) => {
                              handleToggleChange(toggleValue);
                            }}
                          />
                        </div>
                      </ClayLayout.Col>
                  }
                 
                </ClayLayout.Row>
              }
              
              { isConsoleReminderLogs ? searchValue &&
              <Table
                rowClassName="repo-log-table"
                headClassName="console-rem-log-table-filter"
                definitions={reminderLogConstants(handleResetFilter, isConsoleReminderLogs)}
                dataSource={data}
                borderedColumns={false}
                borderless={true}
                headVerticalAlignment={"middle"}
                headingNoWrap={true}
                hover={false}
                noWrap={true}
                responsive={true}
                responsiveSize={"sm"}
                striped={true}
                tableVerticalAlignment={"middle"}
                handleFilter={handleFilter}
                filterValue={filterValue}
                sort={sort}
                handleSort={handleSort}
                pagination={paginationInfo}
                onActivePageChange={handlePagination}
                showPagination={true}
              />
               : 
              <Table
              rowClassName="repo-log-table"
              headClassName="rem-log-table-filter"
              definitions={reminderLogConstants(handleResetFilter, isConsoleReminderLogs)}
              dataSource={data}
              borderedColumns={false}
              borderless={true}
              headVerticalAlignment={"middle"}
              headingNoWrap={true}
              hover={false}
              noWrap={true}
              responsive={true}
              responsiveSize={"sm"}
              striped={true}
              tableVerticalAlignment={"middle"}
              handleFilter={handleFilter}
              filterValue={filterValue}
              sort={sort}
              handleSort={handleSort}
              pagination={paginationInfo}
              onActivePageChange={handlePagination}
              showPagination={true}
            /> }
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default ReminderLogs;
