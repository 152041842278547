import authToken from "@common-services/util/authToken";
import { ROUTE_PATHS } from "@constants";
import axios from "axios";

import * as actions from "../api-action-creator";
import { startLoading, stopLoading } from "../store/slice/globalAPILoader.slice";
import { showAlert } from "../store/slice/globalAlertHandler.slice";
import { cacheResponse, getCachedResponse } from "./api-cache";

const api =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type !== actions.apiCallBegan.type) {
      return next(action);
    }

    const {
      method,
      config,
      onStart,
      onSuccess,
      onError,
      isFormData = false,
      errorMessage = "",
      successMessage = "",
    } = action.payload;
    const { hideLoader, skipErrorHandling, errorConfig } = action.payload;
    const { cache } = action.payload;
    let { data, url } = action.payload;

    const authHeaders = authToken(url, data, method);

    if (isFormData) {
      const formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      data = formData;
    }

    if (cache) {
      const response = getCachedResponse({ url, data }, cache);

      if (response) {
        //if cached response found, return
        dispatch({ type: onSuccess, payload: response });
        next(action);
        return;
      }
    }

    const errorHandler = (message, additionalInfo, skipErrorAction) => {
      if (skipErrorHandling) {
        return;
      }

      dispatch(
        showAlert({
          timeout: 5000,
          message,
          additionalInfo,
          ...errorConfig,
        }),
      );

      if (!skipErrorAction) {
        dispatch({ type: onError, payload: message });
      }
    };

    const successHandler = (message, additionalInfo) => {
      if (skipErrorHandling) {
        return;
      }

      dispatch(
        showAlert({
          timeout: 5000,
          type: "success",
          message,
          additionalInfo,
          ...errorConfig,
        }),
      );
    };

    if (onStart) {
      dispatch({ type: onStart });
    }

    next(action);
    if (!hideLoader) {
      dispatch(startLoading());
    }
    const configHeaders = config && config.headers;
    const requestHeaders = { headers: { ...authHeaders, ...configHeaders } };
    let axiosRequest;
    if (method === "GET") {
      axiosRequest = axios.get(url, requestHeaders);
    } else if (method === "POST") {
      axiosRequest = axios.post(url, data, requestHeaders);
    } else if (method === "DELETE") {
      axiosRequest = axios.delete(url, data, requestHeaders);
    } else if (method === "PUT") {
      axiosRequest = axios.put(url, data, requestHeaders);
    }
    // eslint-disable-next-line no-unused-vars
    const response = axiosRequest
      .then((response) => {
        if (response) {
          const payload = response.data;

          // General
          dispatch(actions.apiCallSuccess(payload));
          // Specific
          try {
            if (onSuccess) {
              dispatch({ type: onSuccess, payload });
            }
            if (cache) {
              cacheResponse({ url, data }, payload, cache);
            }

            //Displaying custom error and success message if passed as props during api call
            if ((method === "PUT" || method === "DELETE" || method === "POST") && payload?.success) {
              successHandler(
                successMessage || payload?.message || payload?.data?.body || payload?.data?.successMessage,
                payload?.correlationId, 
              );
            }
          } catch (e) {
            console.error(e);
          }
          if (!hideLoader) {
            dispatch(stopLoading());
          }

          //error handling
          if (!payload.success) {
            let errMessage = "";
            if (payload.errors && payload.errors.length) {
              payload.errors.forEach((error) => {
                errMessage += error.errorMessage || error.message;
                errMessage += "\n";
              });
            } else {
              errMessage = "Something went wrong, please try again!";
            }

            //Displaying custom error message or use error from API if passed as props during api call
            errorHandler(errorMessage || errMessage, payload?.correlationId, true);
          }
        } else {
          errorHandler("Something went wrong, please try again!", null, false);
        }
      })
      .catch((error) => {
        // General
        dispatch(actions.apiCallFailed(error.message));
        // Specific
        let errMessage = "";
        if (error?.response?.data?.errors && error.response.data.errors.length) {
          //Considering only 1st error message from list of errors Object
          errMessage += error?.response?.data?.errors[0].errorMessage;
        }
        if (onError) {
          dispatch({ type: onError, payload: error.response?.data || error.message });
        }
        if (!hideLoader) {
          dispatch(stopLoading());
        }
        const errorMsg =
          errorMessage || errMessage || error.message || "Something went wrong, please try again!";
        //Firefox fix for abort bypass
        if (errorMsg && errorMsg.toLowerCase().includes("aborted")) return;
        //Displaying custom error and success message if passed as props during api call
        errorHandler(errorMsg, "", true);
        //Check if Unauthorized request and redirect to logout
        if (error?.response?.status === 401) {
          window.location.href = ROUTE_PATHS.NO_ACCESS;
        }
      });
  };

export default api;
