import ClayButton from "@clayui/button";
import ClayIcon from "@clayui/icon";
import ClayModal, { useModal } from "@clayui/modal";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import React, { useEffect, useState } from "react";

export function MultiDeleteModal(props: any) {
  const { modalClose, reportIdList, confirmDelete, multiDelete, hardDelete, softDelete, refreshSelection, reportManagement=false } = props;
  const { observer } = useModal({
    onClose: () => modalClose(reportIdList),
  });
  const[capFlag,setCapFlag]=useState(false)
  useEffect(()=>{
    reportIdList.length > 25 ? setCapFlag(true) : setCapFlag(false)
  },[reportIdList])
  return (
    <ClayModal
      observer={observer}
      spritemap={claySpriteImagePath()}
      disableAutoClose={true}
      status="danger"
    >
      {
        reportManagement ? 
        <ClayModal.Header>Confirmation</ClayModal.Header>
        :capFlag ? 
          <ClayModal.Header>Alert!</ClayModal.Header> 
          : <ClayModal.Header>Confirmation</ClayModal.Header>
      }
      
      {
        multiDelete &&
        <ClayModal.Body>
          {
            capFlag ? 
              `Select 25 reports or less for deletion.`
              : `These selected files will be deleted.
                Are you sure?`
          }
        </ClayModal.Body>
      }

      {
        hardDelete &&
        <ClayModal.Body>
          {`These selected files will be deleted permanently.
              Are you sure?`}
        </ClayModal.Body>
      }

      {
        softDelete &&
        <ClayModal.Body>
          {`These selected files will be removed.
              Are you sure?`}
        </ClayModal.Body>
      }
      
      <ClayModal.Footer
        last={
          <ClayButton.Group spaced>
            {
              reportManagement ? 
              <>
                <ClayButton onClick={() => modalClose(reportIdList)} borderless>
                  <ClayIcon symbol="times" spritemap={claySpriteImagePath()} />
                  &nbsp;{"No"}
                </ClayButton>
                  <ClayButton onClick={() => confirmDelete(reportIdList)} borderless>
                  <ClayIcon symbol="check" spritemap={claySpriteImagePath()} />
                  &nbsp;{"Yes"}
                </ClayButton>
              </> :
            !capFlag ?
              <>
                <ClayButton onClick={() => modalClose(reportIdList)} borderless>
                  <ClayIcon symbol="times" spritemap={claySpriteImagePath()} />
                  &nbsp;{"No"}
                </ClayButton>
                  <ClayButton onClick={() => confirmDelete(reportIdList)} borderless>
                  <ClayIcon symbol="check" spritemap={claySpriteImagePath()} />
                  &nbsp;{"Yes"}
                </ClayButton>
              </> :
                <ClayButton onClick={() => refreshSelection()} borderless>
                  <ClayIcon symbol="times" spritemap={claySpriteImagePath()} />
                  &nbsp;{"Close"}
                </ClayButton>
              
              
            }
            
          </ClayButton.Group>
        }
      />
    </ClayModal>
  );
}
