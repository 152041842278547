import ClayCard from "@clayui/card";
import ClayForm from "@clayui/form";
import ClayLayout from "@clayui/layout";
import { FormControl, PageTitle } from "@common-components";
import { Formik } from "formik";
import ClayButton from "@clayui/button";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { downloadNotificationLogs } from "@store/console/emailResponse/slice";
import * as Yup from "yup";
import { IListObj, ISearchDetails } from "utils/interfaces/emailResponse";

const EmailResponse=()=>{
    const formref=useRef(null)
    const dispatch=useDispatch()
    const initialValues:ISearchDetails = {
        fromDate: "",
        toDate: "",
        notificationType: "",
        status: "",
    };
    const [notificationVal,setNotificationVal]=useState<string>("")

    const typeList:IListObj[]=[
        { label:"All", value:""},
        { label:"IBM", value:"IBM"},
        { label:"ISV", value:"ISV"},
        { label:"Broadcast", value:"BROADCAST"},
    ]
    const statusList:IListObj[]=[
        { label:"All", value:""},
        { label:"Success", value:"SUCCESS"},
        { label:"Failure", value:"FAILURE"},
        { label:"Warning", value:"WARNING"},
    ]
    const pageTitle="Email & Broadcast Messages Sent Log"

    const setNotificationDetail=(val:string)=>{
        formref.current?.setFieldValue("notificationType", val);
        setNotificationVal(val)
    }
    const validationSchema = Yup.object().shape({
        fromDate: Yup.string().required("Start date is required"),
        toDate: Yup.string().required("End date is required")
        .test("End Date test", function(value:any) {
            const { path, createError } = this;
            if(new Date(value) < new Date(formref.current?.values.fromDate))
                return createError({ path, message: "End date can't be before start date" });
            else
                return true
        }),
    });
    const onSubmit = (data:ISearchDetails)=>{
        let obj:ISearchDetails={
            fromDate:dateFormat(data.fromDate),
            toDate:dateFormat(data.toDate),
            notificationType: data.notificationType,
            status: data.status
        }
        dispatch(downloadNotificationLogs(obj))
    }
    const onReset=()=>{
        formref.current?.resetForm()
        setNotificationVal("")
    }
    const dateFormat=(data:string)=>{
        let strArr=data.split("-")
        let result=`${strArr[2]}-${strArr[0]}-${strArr[1]}`
        return result
    }
    return (
        <>
            <ClayLayout.ContainerFluid view size={false}>
                <ClayLayout.Row justify="start" className="align-items-center">
                    <ClayLayout.Col className="d-flex align-items-center">
                        <PageTitle title={pageTitle} />
                    </ClayLayout.Col>
                </ClayLayout.Row>
                <ClayLayout.Row>
                    <ClayCard className="m-3 w-100">
                        <ClayCard.Body className="p-4">
                            <ClayLayout.Row className="pb-3">
                            
                            </ClayLayout.Row>
                            
                            <Formik
                                enableReinitialize={true}
                                destroyOnUnmount={false}
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                                innerRef={formref}
                            >
                                {(formik) => (
                                <ClayForm noValidate onSubmit={formik.handleSubmit}>
                                    <ClayLayout.Row>
                                        <ClayLayout.Col size={3}>
                                            <label><span className="text-red">* </span>Start Date</label>
                                            <FormControl
                                                control="date"
                                                name="fromDate"
                                                id="fromDate"
                                                autoComplete={"off"}
                                                dateFormat="MM-dd-yyyy"
                                                placeholder="Start Date"
                                                size=""
                                                required={true}
                                            />
                                        </ClayLayout.Col>
                                        <ClayLayout.Col size={3}>
                                            <label><span className="text-red">* </span>End Date</label>
                                            <FormControl
                                                control="date"
                                                name="toDate"
                                                id="toDate"
                                                autoComplete={"off"}
                                                dateFormat="MM-dd-yyyy"
                                                placeholder="End Date"
                                                size=""
                                                required={true}
                                            />
                                        </ClayLayout.Col>
                                        <ClayLayout.Col size={3}>
                                            <FormControl
                                                control="select"
                                                name="notificationType"
                                                label="Type"
                                                autoComplete={"off"}
                                                options={typeList}
                                                required={false}
                                                onChange={(e:any)=>{
                                                    setNotificationDetail(e.currentTarget.value)
                                                }}
                                            />
                                        </ClayLayout.Col>
                                        <ClayLayout.Col size={3}>
                                            <FormControl
                                                control="select"
                                                name="status"
                                                label="Status"
                                                autoComplete={"off"}
                                                options={statusList}
                                                required={false}
                                                disabled={notificationVal === "BROADCAST" ? true : false}
                                            />
                                        </ClayLayout.Col>
                                    </ClayLayout.Row>
                                
                                    <ClayLayout.Row justify="end" className="align-items-center">
                                        <ClayLayout.Col className="d-flex align-items-center flex-row-reverse">
                                            <ClayButton
                                                className="ml-3"
                                                displayType="secondary"
                                                onClick={onReset}
                                            >
                                                Clear
                                            </ClayButton>
                                            <ClayButton
                                                className="ml-3"
                                                type="submit"
                                                displayType="primary"
                                            >
                                                Export
                                            </ClayButton>
                                        </ClayLayout.Col>
                                    </ClayLayout.Row>
                                </ClayForm>
                                )}
                            </Formik>
                        </ClayCard.Body>
                    </ClayCard>
                </ClayLayout.Row>
            </ClayLayout.ContainerFluid>
        </>
    )
}
export default EmailResponse;