import { BASEPATH } from "../../utils/constants/constants";

export const SITE_DETAILS_URL = {
  GET_SITE_DETAILS: `${BASEPATH}/management/getlist`,
  SITE_SCRT_REPORTS_URL: `${BASEPATH}/reports/search`,
  SITE_USER_URL: `${BASEPATH}/erpToScrt/users-by-siteid`,
  UPDATE_SITE_DETAIL: `${BASEPATH}/management/logCommissionedDecommissionedStatusChange`,
  UPDATE_ADMIN_SITE_DETAIL: `${BASEPATH}/management/manageSerialNumbersStatusChange`,
  SEND_TO_ERP: `${BASEPATH}/management/resend-to-erp`,
};

export const EDIT_SITE_DETAILS_TABS = {
  scrtReports: "SCRT Reports",
  users: "Users",
};

export const EDIT_SITE_DETAILS_TAB_LIST = [
  {
    display: "SCRT Reports",
    show: true,
    navPath: EDIT_SITE_DETAILS_TABS.scrtReports,
  },
  {
    display: "Users",
    show: true,
    navPath: EDIT_SITE_DETAILS_TABS.users,
  },
];
