import ClayCard from "@clayui/card";
import ClayLayout from "@clayui/layout";
import { DropdownFilter, Table } from "@common-components";
import { ActionComponent } from "@components/ActionComponent/ActionComponent";
import TableCellRenderer from "@components/cellRenderer/TableCellRenderer";
import { Preview } from "@components/preview/Preview";
import { ResetFilter } from "@components/resetFilter/ResetFilter";
import { DROPDOWN_OPTIONS, SOURCE_OBJ } from "@components/screens/repository/constants";
import { DEFAULT_DELTA, DONWLOAD_SOURCE, ROLE_USER, ROUTE_PATHS } from "@constants";
import { IRepositoryDetails, ISignedinUser } from "@interfaces";
import {
  getPreviewDetails,
  getRepoFilterState,
  getRepoPageState,
  getRepoSortState,
  getReportId,
  getRepositoryData,
  getRepositoryDetails,
  handlePaginationState,
  loadPreviewDetails,
  loadRepositoryDetails,
  repositoryListReceived,
  resetFilters,
  resetPreview,
  resetRepositoryState,
} from "@store/repositoryDetails/slice";
import { getSignedInUser } from "@store/userDetails/slice";
import { GET_USER_ROLE, isAuditRepoAdmin, isGTOMSDSupport } from "@utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./sites.scss"

function ScrtReports(props: any) {
  const dispatch = useDispatch();
  const previewDetails = useSelector(getPreviewDetails);
  const reportId = useSelector(getReportId);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const filterValue = useSelector(getRepoFilterState);
  const paginationInfo = useSelector(getRepoPageState);
  const sort = useSelector(getRepoSortState);

  const data = useSelector(getRepositoryDetails);

  const rowData = useSelector(getRepositoryData);
  const signedInUser: ISignedinUser = useSelector(getSignedInUser);
  const auditRepoAdmin = isAuditRepoAdmin(signedInUser?.role)
  const gtoMsdSupport = isGTOMSDSupport(signedInUser?.role)

  useEffect(() => {
    if (GET_USER_ROLE() === ROLE_USER) {
      window.location.href = ROUTE_PATHS.NO_ACCESS;
    }
    //Resetting State For Repo Details
    return () => {
      dispatch(resetRepositoryState());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newData: IRepositoryDetails[] =
      (rowData &&
        rowData.content &&
        rowData.content.length &&
        rowData.content.map((row: IRepositoryDetails) => ({
          ...row,
          isChecked: false,
        }))) ||
      [];
    dispatch(repositoryListReceived(newData));
    const newPageInfo = {
      activeDelta: rowData?.size || DEFAULT_DELTA,
      activePage: rowData?.number + 1 || 1,
      ellipsisBuffer: 5,
      totalItems: rowData.totalElements || 0,
    };
    dispatch(handlePaginationState(newPageInfo));
  }, [rowData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reportId && previewDetails) {
      setIsDrawerOpen(true);
    }
  }, [previewDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePreview = (rowData: IRepositoryDetails) => {
    setIsDrawerOpen(false);
    dispatch(loadPreviewDetails(rowData?.reportId));
  };

  const handleResetFilter = () => {
    let isExistingFilter = false;

    // check if any filters are present
    if (Object.keys(filterValue).length === 0) {
      isExistingFilter = false;
    } else {
      for (let key in filterValue) {
        if (filterValue.hasOwnProperty(key)) {
          isExistingFilter = filterValue[key] !== "" ? true : false;
          break;
        }
      }
    }

    // reset filters only if there are any filters
    if (isExistingFilter) {
      dispatch(resetFilters());
    }
  };

  const SCRT_REPORTS_COLUMNS = [
    {
      headerName: "Source",
      field: "source",
      sortable: false,
      filter: { show: true },
      filterRenderer: <DropdownFilter options={DROPDOWN_OPTIONS} />,
      cellRenderer: <TableCellRenderer fieldName="source" type="icon" obj={SOURCE_OBJ} />,
    },
    {
      headerName: "Uploaded",
      field: "uploadDate",
      sortable: true,
      filter: { show: true, type: "date", range: true },
      cellRenderer: <TableCellRenderer fieldName="uploadDate" type="date" />,
      width: "7rem"
    },
    { headerName: "Uploaded By", field: "uploadedBy", sortable: true, filter: { show: true } },
    { headerName: "Submitted By", field: "reporterName", sortable: true, filter: { show: true }, width:"10rem" },
    {
      headerName: "Customer Name",
      field: "customerName",
      sortable: true,
      filter: { show: true },
      width: "10rem"
    },
    {
      headerName: "Serial Number",
      field: "serialNumber",
      sortable: true,
      filter: { show: true },
      width: "10rem"
    },
    {
      headerName: "Period Start",
      field: "periodStart",
      sortable: true,
      type: "datePicker",
      filter: { show: true, type: "date", range: true },
      cellRenderer: <TableCellRenderer fieldName="periodStart" type="date" skipTimezone="true" />,
      width: "8rem"
    },
    {
      headerName: "Period End",
      field: "periodEnd",
      sortable: true,
      type: "datePicker",
      filter: { show: true, type: "date", range: true },
      cellRenderer: <TableCellRenderer fieldName="periodEnd" type="date" skipTimezone="true" />,
      width: "8rem"
    },
    {
      headerName: "Action",
      field: "action",
      sortable: false,
      filter: { show: true },
      filterRenderer: <ResetFilter handleResetFilter={handleResetFilter} />,
      cellRenderer: (
        <ActionComponent
          disabledDownload={false}
          preview={handlePreview}
          rowData={data}
          showDelete={false}
          source={DONWLOAD_SOURCE.Repository}
          auditRepoAdmin={(auditRepoAdmin || gtoMsdSupport) ? true : false}
        ></ActionComponent>
      ),
    },
  ];

  //Api call to fetch Site Reports
  const getSiteDetails = (
    sortInfo: any = sort,
    filter: any = filterValue,
    pageInfo: any = paginationInfo,
  ) => {
    const sortState = sortInfo && sortInfo.column ? sortInfo : null;
    dispatch(loadRepositoryDetails(props.siteId, sortState, filter, pageInfo));
  };

  //Handler function when filter is applied
  const handleFilter = (val: any) => {
    const newPagination = { ...paginationInfo, activePage: 1, activeDelta: DEFAULT_DELTA };
    getSiteDetails(null, val, newPagination);
  };

  //Handler function when sort is applied
  const handleSort = (val: any) => {
    dispatch(loadRepositoryDetails(props.siteId, val, null, null));
  };

  //Handler function when pagination is applied
  const handlePagination = (page: any, delta: number) => {
    const newPagination = { ...paginationInfo, activePage: page, activeDelta: delta };
    getSiteDetails(null, null, newPagination);
  };

  const handleDrawerChange = () => {
    setIsDrawerOpen(false);
    dispatch(resetPreview(null));
  };

  return (
    <React.Fragment>
      <ClayLayout.Row>
        <ClayCard className="m-3 w-100">
          <ClayCard.Body className="p-4">
            <Table
              headClassName="site-detail-filter-height"
              definitions={SCRT_REPORTS_COLUMNS}
              dataSource={data}
              borderedColumns={false}
              borderless={true}
              headVerticalAlignment={"middle"}
              headingNoWrap={true}
              hover={false}
              noWrap={true}
              responsive={true}
              responsiveSize={"sm"}
              striped={true}
              tableVerticalAlignment={"middle"}
              handleFilter={handleFilter}
              filterValue={filterValue}
              sort={sort}
              handleSort={handleSort}
              pagination={paginationInfo}
              onActivePageChange={handlePagination}
              showPagination={true}
            />
          </ClayCard.Body>
        </ClayCard>
      </ClayLayout.Row>
      <div>
        {reportId && isDrawerOpen && (
          <Preview
            isDrawerOpen={isDrawerOpen}
            handleDrawerChange={handleDrawerChange}
            previewDetailsData={previewDetails}
          />
        )}
      </div>
    </React.Fragment>
  );
}

export default ScrtReports;
