import ClayButton from "@clayui/button";
import ClayTabs from "@clayui/tabs";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../../components/screens/console/console.scss"

const NavTabs = (props: any) => {
  const { disableNav, defaultTab, tabList, type, style, consoleNavBar=false } = props;

  const history = useHistory();
  const [activeTabKeyValue, setActiveTabKeyValue] = useState(0);
  const [defaultTabIndex, setDefaultTabIndex] = useState(0);

  useEffect(() => {
    if (defaultTab) {
      for (const tabIdx in tabList) {
        if (tabList[tabIdx].navPath === defaultTab) {
          setDefaultTabIndex(Number(tabIdx));
          break;
        }
      }
      setActiveTabKeyValue(defaultTabIndex);
    }
  }, [defaultTab, defaultTabIndex, tabList]);

  const handleNavigation = (navPath: any, activePath: number) => {
    if (!disableNav && navPath) {
      history.push(navPath);
    }

    setActiveTabKeyValue(activePath);
    props.tabChanged(navPath, activePath);
  };

  const getTabDisplay = () => {
    return (
      <ClayTabs modern className={style}>
        {tabList.map((tab: any, idx: number) => {
          if (tab.show) {
            return (
              consoleNavBar ? 
              <ClayTabs.Item
                active={activeTabKeyValue === idx}
                onClick={() => handleNavigation(tab.navPath, idx)}
                innerProps={{
                  "aria-controls": "tabpanel-" + idx,
                }}
                key={idx}
                id={tab.id || tab.idx}
                className={"console-nav-link"}
              >
                {tab.display}
              </ClayTabs.Item>
              :
              <ClayTabs.Item
                active={activeTabKeyValue === idx}
                onClick={() => handleNavigation(tab.navPath, idx)}
                innerProps={{
                  "aria-controls": "tabpanel-" + idx,
                }}
                key={idx}
                id={tab.id || tab.idx}
              >
                {tab.display}
              </ClayTabs.Item>
            );
          } else {
            return <></>;
          }
        })}
      </ClayTabs>
    );
  };

  const getButtonGroupDisplay = () => {
    return (
      <ClayButton.Group className="flex-nowrap">
        {tabList.map((tab: any, idx: number) => {
          if (tab.show) {
            return (
              <ClayButton
                key={idx}
                onClick={() => handleNavigation(tab.navPath, idx)}
                displayType={activeTabKeyValue !== idx ? "secondary" : "primary"}
                className={activeTabKeyValue !== idx ? "" : "border-0"}
              >
                {tab.display}
              </ClayButton>
            );
          } else {
            return <></>;
          }
        })}
      </ClayButton.Group>
    );
  };

  const getDisplayLayout = () => {
    if (type === "BUTTON_GROUP") {
      return getButtonGroupDisplay();
    } else {
      return getTabDisplay();
    }
  };

  return getDisplayLayout();
};

export { NavTabs };
