import ClayButton from "@clayui/button";
import ClayCard from "@clayui/card";
import ClayForm from "@clayui/form";
import ClayIcon from "@clayui/icon";
import ClayLayout from "@clayui/layout";
import { ConfirmSendModal, FormControl, NavTabs, PageTitle } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import { DownloadComponent } from "@components/ActionComponent/DownloadComponent";
import { DONWLOAD_SOURCE, ROLE_SUPER_ADMIN, ROLE_AUDIT_REPO_ADMIN, ROUTE_PATHS } from "@constants";
import { IAlertMessage } from "@interfaces";
import {
  checkForBillingNonBillingSiteID,
  getCustomerList,
  getCustomerNumbers,
  getIsBillingNonBilling,
  getIsLoading,
  getUpdateCustomerResponse,
  updateSiteIdByCustomerNumber,
} from "@store/manage/slice";
import { GET_USER_ROLE } from "@utils";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import Alert from "./../../Alert";
import {
  DEFAULT_FORM_DATA,
  EDIT_SITE_MAPPING_TABS,
  EDIT_SITE_MAPPING_TAB_LIST,
  getPageTitle,
} from "./constants";
import "./siteMapping.scss";

const objCustomerNumberMaping: any = [];

function SiteMapping() {
  const dispatch = useDispatch();
  const customerList = useSelector(getCustomerList);
  const isLoading = useSelector(getIsLoading);
  const customerNumberRef: any = useRef();
  const navTabs = EDIT_SITE_MAPPING_TAB_LIST;
  const [currentTab, setCurrentTab] = useState(EDIT_SITE_MAPPING_TABS.mapCN);
  const location = useLocation();
  const isAuditMapping = location.pathname === ROUTE_PATHS.AUDIT_MAPPING;

  const updateCustomerResponse = useSelector(getUpdateCustomerResponse);
  const [formData, setFormData] = useState({ ...DEFAULT_FORM_DATA });

  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";

  const [isModalOpen, setModalOpen] = useState(false);
  const [options, setOptions] = useState([]);

  const [sendAlerts, setSendAlerts] = useState([] as IAlertMessage[]);
  const billingData = useSelector(getIsBillingNonBilling);
  const [onLoadFlag, setOnLoadFlag] = useState(false)

  useEffect(() => {
    //Check if Valid user
    if (isOktaEnabled && isValidUser !== "true") {
      window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
    } else if ((GET_USER_ROLE() !== ROLE_SUPER_ADMIN && GET_USER_ROLE() !== ROLE_AUDIT_REPO_ADMIN)) {
      window.location.href = ROUTE_PATHS.NO_ACCESS;
    }
    dispatch(getCustomerNumbers(isAuditMapping ? location.pathname : currentTab));
  }, [currentTab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Object.keys(updateCustomerResponse).length > 0) {
      setSendAlerts([
        {
          type: updateCustomerResponse.data?.statusCodeValue === 404 ? "error" : "success",
          message: updateCustomerResponse.data?.message ? updateCustomerResponse.data?.message : updateCustomerResponse.data?.body,
        },
      ]);
      setModalOpen(false);
      dispatch(getCustomerNumbers(isAuditMapping ? location.pathname : currentTab));
      setFormData({ ...DEFAULT_FORM_DATA });
    }
  }, [updateCustomerResponse]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const optionsTemp: any =
      (customerList &&
        customerList.length &&
        customerList.map((i: any, idx: number) => {
          objCustomerNumberMaping[i.customerNumber] = i;
          return { label: i.customerNumber + " (" + i.customerName + ")", value: i.customerNumber };
        })) ||
      [];
    setOptions(optionsTemp);
  }, [customerList]);

  useEffect(() => {
    if(isAuditMapping){
      if (billingData) {
        setModalOpen(true);
      }
    }else{
      if (billingData && onLoadFlag) {
        setModalOpen(true);
      }
    }
  }, [billingData]);

  const handleDataChange = (e: string, type: string) => {
    let data: any = { ...formData };
    data[type] = e;
    if (e !== "" && type === "customerNumber") {
      data = { ...objCustomerNumberMaping[e] };
      data["siteId"] = formData.siteId;
    }
    setFormData(data);
  };

  const validationSchema = Yup.object().shape({
    siteId: Yup.number()
      .typeError("Site id allows only numbers.")
      .required("Site id is required.")
      .test(
        "len",
        "Must be a positive integer value",
        (val: any) => !val || val.toString().length > 0,
      ),
  });

  const onSubmit = () => {
    setOnLoadFlag(true)
    handleUploadAlertClose();
    if (options.length > 0 && options[0]) {
      setFormData({ ...formData });
    }
    if (!isAuditMapping && currentTab === EDIT_SITE_MAPPING_TABS.mapExistingCN) {
      dispatch(checkForBillingNonBillingSiteID(formData));
    } else {
      setModalOpen(true);
    }
  };

  const handleConfirmSend = () => {
    document.body.style.overflow = "visible";
    dispatch(
      updateSiteIdByCustomerNumber(formData, isAuditMapping ? location.pathname : currentTab),
    );
    setModalOpen(false);
  };

  const handleUploadAlertClose = () => {
    setSendAlerts([]);
  };

  const handleTabChanged = (navPath: any) => {
    setCurrentTab(navPath);
    setFormData({ ...DEFAULT_FORM_DATA });
    handleUploadAlertClose();
  };

  const modalClose = () => {
    document.body.style.overflow = "visible";
    setModalOpen(false);
  };

  let confirmModalBody;
  if (!!isModalOpen) {
    const message = `<div>Confirm mapping of Customer Number <b>${formData?.customerNumber}</b>`;
    confirmModalBody =
      isAuditMapping || currentTab === EDIT_SITE_MAPPING_TABS.mapCN
        ? `${message} to Support Site ID <b> ${formData?.siteId}</b>?</div>`
        : `${message} from Support Site ID <br>&emsp;<b>${formData.oldSiteId}</b> ${
            billingData.oldSiteIdBilling ? "(Billing)" : "(Non-Billing)"
          }  to <b>${formData.siteId}</b> ${
            billingData.newSiteIdBilling ? "(Billing)" : "(Non-Billing)"
          } </div>`;
  }

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row justify="start" className="align-items-center">
          <ClayLayout.Col className="d-flex align-items-center">
            <PageTitle title={getPageTitle(isAuditMapping ? location.pathname : currentTab)} />
          </ClayLayout.Col>
        </ClayLayout.Row>
        <ClayLayout.Row>
          <ClayCard className="m-3 w-100">
            {!isAuditMapping && (
              <ClayLayout.Row className={"mt-4 ml-4"}>
                <ClayLayout.Col size={12}>
                  <NavTabs
                    tabList={navTabs}
                    disableNav="true"
                    defaultTab={currentTab}
                    tabChanged={handleTabChanged}
                  />
                </ClayLayout.Col>
              </ClayLayout.Row>
            )}
            <ClayCard.Body className="p-4">
              <Formik
                enableReinitialize={true}
                initialValues={formData}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
              >
                {(formik) => (
                  <ClayForm onSubmit={formik.handleSubmit} className="m-4 mw-100">
                    {!isLoading && sendAlerts && sendAlerts.length ? (
                      <div className="row">
                        {sendAlerts.map((report: IAlertMessage, key: number) => {
                          return (
                            <div
                              key={key}
                              className={`${
                                sendAlerts.length > 1 ? "col-md-6" : "col-md-12"
                              } col-sm-12`}
                            >
                              {report.type && report.message && (
                                <div className="c-mt-2" key={report.message}>
                                  { 
                                  isAuditMapping ? 
                                    <Alert handleClose={handleUploadAlertClose} report={report} />
                                  : onLoadFlag && <Alert handleClose={handleUploadAlertClose} report={report} />}
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                    {!isLoading &&
                      (options.length > 0 ? (
                        <>
                          <ClayLayout.Row justify="start">
                            <ClayLayout.Col xs={12} md={10} lg={6} className="mt3">
                              <FormControl
                                control="autocomplete"
                                id="customerNumber"
                                label="Customer number"
                                labelKey="label"
                                align="justify"
                                name="customerNumber"
                                required
                                options={options}
                                selected={
                                  formData.customerNumber
                                    ? [
                                        {
                                          label:
                                            formData.customerNumber +
                                            " (" +
                                            formData.customerName +
                                            ")",
                                          value: formData.customerNumber,
                                        },
                                      ]
                                    : []
                                }
                                onChange={(e: any) => {
                                  handleDataChange(
                                    e.length > 0 ? e[0].value : "",
                                    "customerNumber",
                                  );
                                }}
                                customerNumberRef={customerNumberRef}
                                style={{ backgroundColor: "white" }}
                              />
                            </ClayLayout.Col>
                            <ClayLayout.Col xs={12} md={10} lg={6} className="mt3">
                              <FormControl
                                className="siteIdInput"
                                control="text"
                                id="siteId"
                                label={
                                  isAuditMapping || currentTab === EDIT_SITE_MAPPING_TABS.mapCN
                                    ? `Support Site ID`
                                    : `New Support Site ID`
                                }
                                name="siteId"
                                autoComplete={"off"}
                                onChange={(e: any) => handleDataChange(e.target.value, "siteId")}
                                required={true}
                                type="number"
                                style={{ backgroundColor: "white" }}
                                min={0}
                                placeholder={"Enter a valid Support Site ID"}
                              />
                            </ClayLayout.Col>
                          </ClayLayout.Row>
                          {formData.customerNumber?.length > 0 && (
                            <React.Fragment>
                              <ClayLayout.Row justify="start">
                                <ClayLayout.Col xs={12} md={10} lg={6}>
                                  <FormControl
                                    control="text"
                                    label="Customer name"
                                    name="customername"
                                    id="customername"
                                    autoComplete={"off"}
                                    value={formData?.customerName}
                                    readOnly
                                    disabled
                                  />
                                </ClayLayout.Col>
                              </ClayLayout.Row>
                              {!isAuditMapping &&
                                currentTab === EDIT_SITE_MAPPING_TABS.mapExistingCN && (
                                  <ClayLayout.Row justify="start">
                                    <ClayLayout.Col xs={12} md={10} lg={6}>
                                      <FormControl
                                        control="text"
                                        label="Existing Site ID"
                                        name="oldSiteId"
                                        id="oldSiteId"
                                        autoComplete={"off"}
                                        value={formData?.oldSiteId}
                                        readOnly
                                        disabled
                                      />
                                    </ClayLayout.Col>
                                  </ClayLayout.Row>
                                )}
                              <ClayLayout.Row justify="start">
                                <ClayLayout.Col xs={12} md={10} lg={6}>
                                  <FormControl
                                    control="text"
                                    label="Customer email"
                                    name="reporterEmail"
                                    id="reporterEmail"
                                    autoComplete={"off"}
                                    value={formData?.reporterEmail || "-"}
                                    readOnly
                                    disabled
                                  />
                                </ClayLayout.Col>
                              </ClayLayout.Row>
                              <ClayLayout.Row className="d-flex justify-start align-items-center">
                                <ClayLayout.Col xs={12} md={10} lg={6}>
                                  <FormControl
                                    control="text"
                                    label="File name"
                                    name="filename"
                                    value={formData?.fileName || "No Report Found"}
                                    readOnly
                                    disabled
                                  />
                                </ClayLayout.Col>
                                {formData?.reportId && (
                                  <ClayLayout.Col xs={12} md={10} lg={6} className="mt3">
                                    <DownloadComponent
                                      reportIdList={[parseInt(formData?.reportId)]}
                                      fileName={formData.crushFtpFilename}
                                      source={DONWLOAD_SOURCE.Repository}
                                    />
                                  </ClayLayout.Col>
                                )}
                              </ClayLayout.Row>
                            </React.Fragment>
                          )}
                          <ClayLayout.Row className="mt-3">
                            {options.length > 0 && (
                              <ClayButton
                                type="submit"
                                className="ml-2"
                                displayType={"primary"}
                                style={{ fontSize: "small" }}
                                disabled={
                                  formData.customerNumber?.length === 0 ||
                                  formData.siteId?.length === 0
                                }
                              >
                                <span className="inline-item inline-item-before">
                                  <ClayIcon
                                    spritemap={claySpriteImagePath()}
                                    symbol="angle-right"
                                  />
                                </span>
                                Update
                              </ClayButton>
                            )}
                          </ClayLayout.Row>
                        </>
                      ) : (
                        <div>All customers mapped to site Id's.</div>
                      ))}
                  </ClayForm>
                )}
              </Formik>
            </ClayCard.Body>
          </ClayCard>
        </ClayLayout.Row>
      </ClayLayout.ContainerFluid>
      {isModalOpen && (
        <ConfirmSendModal
          confirmSend={handleConfirmSend}
          modalClose={() => modalClose()}
          body={confirmModalBody}
        />
      )}
    </React.Fragment>
  );
}

export default SiteMapping;
