import { configureStore, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import { EMAIL_RESPONSE_URL } from "./constant";
import { axiosApi } from "@common-services/redux-helper/middleware/axios-api";
import { showAlert } from "@common-services/redux-helper/store/slice/globalAlertHandler.slice";
import {CRUSH_FTP_DOWNLOAD} from "../../download/crushFTPDownload"

const slice = createSlice({
  name: "emailResponse",
  initialState: {
    notificationDownloadFlag:false
  },
  reducers: {
    setNotificationDownloadFlag: (emailResponse,action) => {
        if(action.payload.success){
            emailResponse.notificationDownloadFlag=action.payload.success;
        }else{
            emailResponse.notificationDownloadFlag=action.payload
        }
    },
  },
});

export const {
    setNotificationDownloadFlag
} = slice.actions;

export const downloadNotificationLogs = ( body ) => (dispatch, getState) => {
    dispatch(setNotificationDownloadFlag(false))
    axiosApi({
      url: EMAIL_RESPONSE_URL.GET_NOTIFICATION_LOGS,
      method: "POST",
      data: body,
      skipErrorHandling: true,
      responseType: "blob",
      headers: {
        responseType: "stream",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setNotificationDownloadFlag(true))
          const fileName = "EmailNotificationLogs.xlsx";
          const isSingleFileOrder = true;
          CRUSH_FTP_DOWNLOAD(fileName, isSingleFileOrder, response);
        }
      })
      .catch((error) => {
        dispatch(setNotificationDownloadFlag(false))
        dispatch(
          showAlert({
            timeout: 3000,
            message: "Unable to download file!",
          }),
        );
      });
};


export const store = configureStore({
  reducer: slice.reducer,
});

export const getNotificationDownloadFlag = (state) => state.emailResponse.notificationDownloadFlag;


// reducer
export const emailResponseReducer = slice.reducer;
