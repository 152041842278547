import ClayCard from "@clayui/card";
import ClayForm, { ClayToggle } from "@clayui/form";
import ClayLayout from "@clayui/layout";
import { FormControl } from "@common-components";
import { Table } from "@common-components";
import { getEnvVars } from "@common-services/redux-helper/store/slice/initializeApp.slice";
import TableCellRenderer from "@components/cellRenderer/TableCellRenderer";
import { ROLE_SUPER_ADMIN, ROUTE_PATHS } from "@constants";
import { IProfile } from "@interfaces";
import {
  getAdminUserSiteDetails,
  getSignedInUser,
  getUserSiteDetails,
  updateUserDetails,
} from "@store/userDetails/slice";
import { GET_USER_ROLE } from "@utils";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import "./profile.scss";

const TableRenderName = (props: any) => {
  const { rowData } = props;
  return (
    <span>
      {rowData.firstName} {rowData.lastName} ({rowData.userId})
    </span>
  );
};

function UserInformation() {
  const history = useHistory();
  const form = useRef(null);
  const signedInUser = useSelector(getSignedInUser);
  const userSiteDetails = useSelector(getUserSiteDetails);
  const adminSiteDetails = useSelector(getAdminUserSiteDetails);
  const dispatch = useDispatch();
  const role = GET_USER_ROLE();
  const [formData, setFormData] = useState({
    name: signedInUser.name,
    email: signedInUser.email,
    announcement: signedInUser.announcement,
  });

  const onSubmit = (data: IProfile) => {
    history.push(ROUTE_PATHS.REPOSITORY);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    email: Yup.string(),
  });

  const onToggle = (flag: boolean) => {
    dispatch(updateUserDetails(flag));
  };

  const envVars = useSelector(getEnvVars);
  const isOktaEnabled = envVars.DISABLE_OKTA !== "true";
  const isValidUser = localStorage.getItem("okta-valid-user") || "";
  const USER_COLUMNS = [
    {
      headerName: "Site ID",
      field: "siteId",
      sortable: false,
      width: "20rem"
    },
    { headerName: "Site Name", field: "companyName", sortable: false, width: "20rem" },
    {
      headerName: "SCRT Access",
      field: "scrtAccess",
      sortable: false,
      width: "20rem",
      cellRenderer: <TableCellRenderer fieldName="scrtAccess" type="active" fieldType="boolean" />,
    },
    {
      headerName: "Admin Access",
      field: "scrtAdmin",
      sortable: false,
      width: "20rem",
      cellRenderer: <TableCellRenderer fieldName="scrtAdmin" type="active" fieldType="boolean" />,
    },
  ];
  const ADMIN_COLUMNS = [
    {
      headerName: "Site ID",
      field: "siteId",
      sortable: false,
      width: "20rem",
    },
    { headerName: "Site Name", field: "companyName", sortable: false, width: "20rem", },
    {
      headerName: "User Name and Email Address",
      field: "userId",
      sortable: false,
      cellRenderer: <TableRenderName />,
      width: "40rem",
    },
  ];

  useEffect(() => {
    if (isOktaEnabled && isValidUser !== "true") {
      window.location.assign(ROUTE_PATHS.OKTA_REDIRECT);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFormData({
      name: signedInUser.name,
      email: signedInUser.email,
      announcement: signedInUser.announcement,
    });
  }, [signedInUser]);

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayCard className="m-3 w-100">
          <ClayCard.Body className="p-4">
            <Formik
              enableReinitialize={true}
              initialValues={formData}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              innerRef={form}
            >
              {(formik) => (
                <ClayForm onSubmit={formik.handleSubmit} className="m-4 mw-100">
                  <ClayLayout.Row justify="start">
                    <ClayLayout.Col xs={12} md={10} lg={6} className="mt3">
                      <FormControl
                        control="text"
                        label="Name"
                        name="name"
                        disabled
                        autoComplete={"off"}
                      />
                    </ClayLayout.Col>
                    <ClayLayout.Col xs={12} md={10} lg={6} className="mt3">
                      <FormControl control="text" label="Email" name="email" disabled />
                    </ClayLayout.Col>
                  </ClayLayout.Row>
                  <ClayLayout.Row justify="start">
                    <ClayLayout.Col xs={12} md={10} lg={6} className="mt3">
                      <div>
                        <label>Announcements</label>
                        <div>
                          <ClayToggle
                            onToggle={(val) => onToggle(val)}
                            toggled={formData.announcement}
                          />
                        </div>
                      </div>
                    </ClayLayout.Col>
                  </ClayLayout.Row>
                  <ClayLayout.Row justify="start">
                    <ClayLayout.Col xs={12} md={12} lg={12} className="mt3">
                      <div>
                        <label>Broadcom Support Site ID Entitlements</label>
                        <div className="site-entitlements">
                          <Table
                            rowClassName="users-table"
                            definitions={USER_COLUMNS}
                            dataSource={userSiteDetails}
                            borderedColumns={true}
                            borderless={true}
                            headVerticalAlignment={"middle"}
                            headingNoWrap={true}
                            hover={false}
                            noWrap={true}
                            responsive={true}
                            responsiveSize={"sm"}
                            striped={true}
                            hideFilter={true}
                            tableVerticalAlignment={"middle"}
                          />
                        </div>
                      </div>
                    </ClayLayout.Col>
                  </ClayLayout.Row>
                  {role !== ROLE_SUPER_ADMIN && (
                    <ClayLayout.Row justify="start">
                      <ClayLayout.Col xs={12} md={12} lg={12} className="mt3">
                        <div className="mt-3">
                          <label>SCRT Account Administrators</label>
                          <div className="site-entitlements">
                            <Table
                              rowClassName="users-table"
                              definitions={ADMIN_COLUMNS}
                              dataSource={adminSiteDetails}
                              borderedColumns={true}
                              borderless={true}
                              headVerticalAlignment={"middle"}
                              headingNoWrap={true}
                              hover={false}
                              noWrap={true}
                              responsive={true}
                              responsiveSize={"sm"}
                              striped={true}
                              hideFilter={true}
                              tableVerticalAlignment={"middle"}
                            />
                          </div>
                        </div>
                      </ClayLayout.Col>
                    </ClayLayout.Row>
                  )}
                </ClayForm>
              )}
            </Formik>
          </ClayCard.Body>
        </ClayCard>
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default UserInformation;
