import { BASEPATH } from "../../utils/constants/constants";

export const REPOSITORY_LOGS_URL = {
  GET_USER_EVENT: `${BASEPATH}/audit/getUserEvent`,
  GET_REMINDER_LOGS: `${BASEPATH}/audit/get-remainder-logs`,
  GET_REPOSITORY_LOGS: `${BASEPATH}/audit/search`,
  UPDATE__REMINDER: `${BASEPATH}/management/update-reminder`
};

export const LOGS_TABS = {
  repositoryLogs: "RepositoryLogs",
  signInSignOutLogs: "Sign In / Sign Out Logs",
  reminderLogs: "Reminder Logs",
};

export const LOGS_TABS_LIST = [
  {
    display: "Repository Logs",
    show: true,
    navPath: LOGS_TABS.repositoryLogs,
  },
  {
    display: "Sign In / Sign Out Logs",
    show: true,
    navPath: LOGS_TABS.signInSignOutLogs,
  },
  {
    display: "Reminder Logs",
    show: true,
    navPath: LOGS_TABS.reminderLogs,
  },
];
