import { axiosApi } from "@common-services/redux-helper/middleware/axios-api";
import { showAlert } from "@common-services/redux-helper/store/slice/globalAlertHandler.slice";
import { configureStore, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import {SCRT_USER_MANAGEMENT_URL} from "./constant"

const slice = createSlice({
  name: "scrtUserManagement",
  initialState: {
    editUserDetail:{
        firstName:"",
        lastName:"",
        userId:"",
        access:"",
        role:"",
        title:""
    },
    userDetailFlag: false,
    filterVal: {},
    scrtUserList:[],
    updateUserFlag:false,
    tableRoleList:[],
    userRoleList:[],
    tableTitleList:[],
    userTitleList:[],
    editTitleList:[]
  },
  reducers: {
    setEditUserDetail: (scrtUserManagement, action) => {
      if(action.payload){
          scrtUserManagement.editUserDetail.firstName = action.payload.firstName 
          scrtUserManagement.editUserDetail.lastName = action.payload.lastName 
          scrtUserManagement.editUserDetail.userId = action.payload.userId 
          scrtUserManagement.editUserDetail.role = action.payload.role
          scrtUserManagement.editUserDetail.access = action.payload.access  
          scrtUserManagement.editUserDetail.title = action.payload.title    
      }
    },
    resetSelectedUserDetail: (scrtUserManagement) => {
      scrtUserManagement.editUserDetail.firstName = "";
      scrtUserManagement.editUserDetail.lastName = "";
      scrtUserManagement.editUserDetail.userId = "";
      scrtUserManagement.editUserDetail.role = "";
      scrtUserManagement.editUserDetail.access = "";
      scrtUserManagement.editUserDetail.title = "";
    },
    setUpdateUserFlag: (scrtUserManagement,action) => {
      if(action.payload.success && action.payload.data){
        scrtUserManagement.updateUserFlag=true
      }else{
        scrtUserManagement.updateUserFlag=action.payload
      }
    },
    setUserDetailFlag: (scrtUserManagement,action) => {
        scrtUserManagement.userDetailFlag = action.payload
    },
    setSCRTUserList: (scrtUserManagement,action) => {
        if(action.payload.data && action.payload.data.result){
          scrtUserManagement.scrtUserList = action.payload.data.result;
        }
    },
    handleFilterState: (state, action) => {
        if (action.payload) {
          state.sort = { column: "", order: "" };
          state.filterVal = action.payload;
        }
    },
    setRoleList: (scrtUserManagement,action) => {
      if(action.payload.data && action.payload.data){
          let formatedRoleList=formatListData(action.payload.data.roles)
          scrtUserManagement.tableRoleList = [{label:"All",value:""},...formatedRoleList]
          scrtUserManagement.userRoleList = [{label:"Select",value:""},...formatedRoleList]
      }
    },
    setTitleList: (scrtUserManagement,action) => {
      if(action.payload.data && action.payload.data){
          let formatedTitleList=formatListData(action.payload.data.titles) 
          scrtUserManagement.tableTitleList = [{label:"All",value:""},...formatedTitleList]
          scrtUserManagement.userTitleList = [{label:"Select",value:""},...formatedTitleList]
          scrtUserManagement.editTitleList = formatedTitleList
      }
    },
  },
});

export const { 
  setEditUserDetail,
  resetSelectedUserDetail,
  setUserDetailFlag,
  setUpdateUserFlag,
  setSCRTUserList,
  handleFilterState,
  setRoleList,
  setTitleList 
} = slice.actions;

export const loadSCRTUsermanagementDetails = ( filterParams ) => (dispatch, getState) => {
    //Need to dispatch events, update state and then make API call
    if (filterParams) {
      dispatch(handleFilterState(filterParams));
    }

    const { URL, body } = getURLWithParams(getState());
    return dispatch(
      apiCallBegan({
        url: URL,
        method: "POST",
        data: body,
        onSuccess: setSCRTUserList.type,
        onError: setSCRTUserList.type,
      }),
    );
  };
  const getURLWithParams = (state) => {
    let URL = SCRT_USER_MANAGEMENT_URL.GET_ADMIN_USER_DETAILS;
    let body = {
        userId: "",
        role: "",
        title: ""
    };

    if (state?.scrtUserManagement?.filterVal) {
      const filters = { ...state.scrtUserManagement.filterVal };
      body = { ...body, ...filters };
    }
    return { URL, body };
  };

  export const addNewAdminUser = ( data ) => (dispatch, getState) => {
    //Need to dispatch events, update state and then make API call
    //dispatch(setUpdateUserFlag(null))
    return dispatch(
      apiCallBegan({
        url: SCRT_USER_MANAGEMENT_URL.ADD_ADMIN_USER_DETAILS,
        method: "POST",
        data: data,
        onSuccess: setUpdateUserFlag.type
      }),
    );
  };

  export const updateAdminUserDetails = ( data ) => (dispatch, getState) => {
    //Need to dispatch events, update state and then make API call
    //dispatch(setUpdateUserFlag(null))
    return dispatch(
      apiCallBegan({
        url: SCRT_USER_MANAGEMENT_URL.UPDATE_ADMIN_USER_DETAILS,
        method: "POST",
        data: data,
        onSuccess: setUpdateUserFlag.type
      }),
    );
  };

  export const fetchRoleList = ( data ) => (dispatch, getState) => {
    //Need to dispatch events, update state and then make API call
    //dispatch(setUpdateUserFlag(null))
    return dispatch(
      apiCallBegan({
        url: SCRT_USER_MANAGEMENT_URL.GET_ADMIN_ROLES_DETAILS,
        method: "GET",
        onSuccess: setRoleList.type
      }),
    );
  };

  export const fetchTitleList = ( data ) => (dispatch, getState) => {
    //Need to dispatch events, update state and then make API call
    
  //   dispatch(setTitleList([
  //     {label: "Title 1",value:"title1"},
  //     {label: "Title 2",value:"title2"},
  // ]))
    return dispatch(
      apiCallBegan({
        url: SCRT_USER_MANAGEMENT_URL.GET_ADMIN_TITLE_DETAILS,
        method: "GET",
        onSuccess: setTitleList.type
      }),
    );
  };

export const resetFilters = () => (dispatch, getState) => {
    dispatch(handleFilterState({}));
  };

const formatListData=(data)=>{
  let formatedList=[]
  data.forEach((val)=>{
    formatedList.push({
      label: val,
      value: val,
    });
  })
  return formatedList;
}  
export const store = configureStore({
  reducer: slice.reducer,
});

export const getEditUserDetail = (state) => state.scrtUserManagement.editUserDetail;
export const getUserDetailFlag = (state) => state.scrtUserManagement.userDetailFlag;
export const getUpdateUserFlag = (state) => state.scrtUserManagement.updateUserFlag;
export const getSCRTUserList = (state) => state.scrtUserManagement.scrtUserList;
export const getFilterState = (state) => state.scrtUserManagement.filterVal;
export const getTableRoleList = (state) => state.scrtUserManagement.tableRoleList;
export const getUserRoleList = (state) => state.scrtUserManagement.userRoleList;
export const getTableTitleList = (state) => state.scrtUserManagement.tableTitleList;
export const getUserTitleList = (state) => state.scrtUserManagement.userTitleList;
export const getEditTitleList = (state) => state.scrtUserManagement.editTitleList;
// reducer
export const scrtUserManagementReducer = slice.reducer;
