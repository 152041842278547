import ClayIcon from "@clayui/icon";
import ClayLayout from "@clayui/layout";
import { NavTabs, PageTitle } from "@common-components";
import { claySpriteImagePath } from "@common-services/util/claySpriteImagePath";
import { ROLE_AUDIT_REPO_ADMIN, ROLE_GTO_MSD_SUPPORT, ROLE_SUPER_ADMIN, ROUTE_PATHS } from "@constants";
import { LOGS_TABS, LOGS_TABS_LIST } from "@store/repositoryLogs/constants";
import { GET_USER_ROLE } from "@utils";
import React, { useState } from "react";

import ReminderLogs from "./ReminderLogs";
import RepositoryLog from "./RepositoryLogs";
import SignInSignOutLogs from "./SignInSignOutLogs";

function Logs(props: any) {
  const pageTitle = `Logs`;

  const navTabs = LOGS_TABS_LIST;

  const [currentTab, setCurrentTab] = useState(LOGS_TABS.repositoryLogs);

  const getTabComponent = () => {
    switch (currentTab) {
      case LOGS_TABS.repositoryLogs:
        return <RepositoryLog />;
      case LOGS_TABS.signInSignOutLogs:
        return <SignInSignOutLogs />;
      case LOGS_TABS.reminderLogs:
        return <ReminderLogs />;
    }
  };

  const handleTabChanged = (navPath: any) => {
    setCurrentTab(navPath);
  };

  const onBack = () => {
    window.location.href = ROUTE_PATHS.REPOSITORY;
  };

  return (
    <React.Fragment>
      <ClayLayout.ContainerFluid view size={false}>
        <ClayLayout.Row justify="start" className="align-items-center">
          <ClayLayout.Col className="d-flex align-items-center">
            <ClayIcon
              symbol="order-arrow-left"
              className="pe-cursor"
              fontSize={"30px"}
              spritemap={claySpriteImagePath()}
              onClick={onBack}
            />
            &emsp;
            <PageTitle title={pageTitle} />
          </ClayLayout.Col>
        </ClayLayout.Row>
        {(GET_USER_ROLE() === ROLE_SUPER_ADMIN || GET_USER_ROLE() === ROLE_AUDIT_REPO_ADMIN || GET_USER_ROLE() === ROLE_GTO_MSD_SUPPORT) && (
          <ClayLayout.Row className={"mt-4"}>
            <ClayLayout.Col size={6}>
              <NavTabs
                tabList={navTabs}
                disableNav="true"
                defaultTab={currentTab}
                tabChanged={handleTabChanged}
              />
            </ClayLayout.Col>
          </ClayLayout.Row>
        )}
        {getTabComponent()}
      </ClayLayout.ContainerFluid>
    </React.Fragment>
  );
}

export default Logs;
