import { ROUTE_PATHS } from "@constants";

export const EDIT_SITE_MAPPING_TABS = {
  mapCN: "Map New Customer Number to Site ID",
  mapExistingCN: "Update Customer Number with new Site ID",
};

export const EDIT_SITE_MAPPING_TAB_LIST = [
  {
    display: "Map New Customer Number to Site ID",
    show: true,
    navPath: EDIT_SITE_MAPPING_TABS.mapCN,
  },
  {
    display: "Update Customer Number with new Site ID",
    show: true,
    navPath: EDIT_SITE_MAPPING_TABS.mapExistingCN,
  },
];

export const getPageTitle = (tabName: any) => {
  return tabName === ROUTE_PATHS.AUDIT_MAPPING
    ? "Customer Number to Support Site ID Audit Mapping"
    : tabName === EDIT_SITE_MAPPING_TABS.mapCN
    ? "Customer Number to Support Site ID Mapping"
    : "Update Customer Number to New Support Site ID";
};

export const DEFAULT_FORM_DATA = {
  customerNumber: "",
  siteId: "",
  customerName: "",
  reporterEmail: "",
  fileName: "",
  reportId: "",
  crushFtpFilename: "",
  oldSiteId: "",
};
