import { configureStore, createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "@common-services/redux-helper/api-action-creator";
import { CATALOG_VERSION_URL } from "./constant";

const slice = createSlice({
  name: "catalogVersion",
  initialState: {
    catalogDetail: [],
    catalogUpdateFlag: false
  },
  reducers: {
    setCatalogDetail: (catalogVersion,action) => {
      if(action.payload.success && action.payload.data.result){
        catalogVersion.catalogDetail=action.payload.data.result;
      }
    },
    setCatalogFlag: (catalogVersion,action) => {
      if(action.payload.success){
        catalogVersion.catalogUpdateFlag=action.payload.success;
      }else{
        catalogVersion.catalogUpdateFlag=action.payload;
      }
    },
  },
});

export const {
  setCatalogDetail,
  setCatalogFlag
} = slice.actions;

export const fetchCatalogVersionDetails = ( ) => (dispatch, getState) => {
  dispatch(setCatalogFlag(false))
  return dispatch(
    apiCallBegan({
      url: CATALOG_VERSION_URL.GET_CATALOG_VERSION,
      method: "GET",
      onSuccess: setCatalogDetail.type,
      onError: setCatalogDetail.type,
    }),
  );
};

export const updateCatalogDetails = ( body ) => (dispatch, getState) => {
  dispatch(setCatalogFlag(false))
  return dispatch(
    apiCallBegan({
      url: CATALOG_VERSION_URL.UPDATE_CATALOG_VERSION,
      method: "POST",
      data: {catalogVersionList: body},
      onSuccess: setCatalogFlag.type,
      onError: setCatalogFlag.type,
    }),
  );
};

export const store = configureStore({
  reducer: slice.reducer,
});

export const getCatalogVersionDetail = (state) => state.catalogVersion.catalogDetail;
export const getCatalogUpdateFlag = (state) => state.catalogVersion.catalogUpdateFlag;


// reducer
export const catalogVersionReducer = slice.reducer;
